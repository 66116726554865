import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiResponse, ApiResponseStatusCode, COUNTRY_DICTIONARY, Country, DictionaryEnum, DocumentItemType, LanguageService, LegalDocument, LegalDocumentAcceptance, LegalDocumentType, LoaderService, NO_ITEMS, Practice, PracticeType, RoleInformationType, RoleTypeEnum, SURGEON_CODE, ToastService, USA_CODE, USER_REQUEST_DICTIONARY_LIST, USState, UsStateLicense, UserProfile, UserRepository, UserRequest, YES_ITEMS, countryMapper, usStateMapper } from '../core';
import { UserRequestForm } from '../models';


/**
* This services handles user account request 
*/
@Injectable()
export class UserRequestService {

	private _userRequestForm: UserRequestForm;
	private _userRequest: UserRequest;
	private _legalDocumentToAccept: LegalDocument[];
	private _legalDocCountryCode: string;

	private _allCountries: Country[] = [];
	private _allUSStates: USState[] = [];
	private _allRoleTypes: RoleInformationType[] = [];
	private _allPracticeTypes: PracticeType[] = [];
	private _legalDocumentTypes: LegalDocumentType[] = [];
	private _documentItemTypes: DocumentItemType[] = [];

	private _emailExistError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	private _successState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	private readonly CAPTCHA_ACTION_REQUEST = "user_request";
	private readonly CAPTCHA_ACTION_ACCEPTANCE = "legal_docs_acceptance";

	constructor(
		private userRepo: UserRepository,
		private toastSrv: ToastService,
		private router: Router,
		private langSrv: LanguageService,
		private recaptchaV3Srv: ReCaptchaV3Service,
		private loaderSrv: LoaderService
	) { }


	/**
	* Get email already exist error status
	*/
	get emailExistError(): Observable<boolean> {
		return this._emailExistError.asObservable();
	}

	/**
	* Get user request success status
	*/
	get successState(): Observable<boolean> {
		return this._successState.asObservable();
	}

	/**
	* Get ordered list of countries
	*/
	getCountries(): Observable<Country[]> {
		return this.userRepo.getDictionaries(COUNTRY_DICTIONARY).pipe(
			map(res => this.handleApiResponse(res)),
			filter(list => !!list && list.length === 1),
			map(list => list[0].values),
			map(list => list.map(i => countryMapper(i))),
			map(list => [...list].sort((a, b) => new Intl.Collator().compare(a.name, b.name)))
		);
	}

	/**
	* Init all dictionaries for user request
	*/
	initDictionaries(): Observable<boolean> {
		if (this._allCountries.length > 0 && this._allUSStates.length > 0 && this._allRoleTypes.length > 0 && this._allPracticeTypes.length > 0 && this._legalDocumentTypes.length > 0 && this._documentItemTypes.length > 0) {
			return of(true);
		}
		return this.userRepo.getDictionaries(USER_REQUEST_DICTIONARY_LIST).pipe(
			map(res => this.handleApiResponse(res)),
			filter(list => !!list && list.length > 0),
			tap(list => {
				const dics = list.map(x => x.name);
				this._allCountries = dics.includes(DictionaryEnum.COUNTRY) ? list.find(x => x.name == DictionaryEnum.COUNTRY).values.map(country => countryMapper(country)) : [];
				this._allUSStates = dics.includes(DictionaryEnum.US_STATE) ? list.find(x => x.name == DictionaryEnum.US_STATE).values.map(state => usStateMapper(state)) : [];
				this._allRoleTypes = dics.includes(DictionaryEnum.ROLE_INFORMATION_TYPE) ? list.find(x => x.name == DictionaryEnum.ROLE_INFORMATION_TYPE).values : [];
				this._allPracticeTypes = dics.includes(DictionaryEnum.PRACTICE_TYPE) ? list.find(x => x.name == DictionaryEnum.PRACTICE_TYPE).values : [];
				this._legalDocumentTypes = dics.includes(DictionaryEnum.LEGAL_DOCUMENT_TYPE) ? list.find(x => x.name == DictionaryEnum.LEGAL_DOCUMENT_TYPE).values : [];
				this._documentItemTypes = dics.includes(DictionaryEnum.DOCUMENT_ITEM_TYPE) ? list.find(x => x.name == DictionaryEnum.DOCUMENT_ITEM_TYPE).values : [];
				this._allCountries.sort((a, b) => new Intl.Collator().compare(a.name, b.name));
				this._allUSStates.sort((a, b) => new Intl.Collator().compare(a.name, b.name));
			}),
			map(list => true)
		);
	}

	/**
	* Get current Country list
	*/
	getAllCountries() {
		return this._allCountries;
	}

	/**
	* Get current Unites States list
	*/
	getAllUSStates() {
		return this._allUSStates;
	}

	/**
	* Get current Role Information Type list
	*/
	getAllRoleTypes() {
		return this._allRoleTypes;
	}

	/**
	* Get current Practice Type list
	*/
	getAllPracticeTypes() {
		return this._allPracticeTypes
	}

	/**
	* Get current Legal Document Type list
	*/
	getLegalDocumentTypes() {
		return this._legalDocumentTypes;
	}

	/**
	* Get current Document Item Type list
	*/
	getDocumentItemTypes() {
		return this._documentItemTypes;
	}

	/**
	* Get current User Request form
	*/
	getUserRequestForm(): UserRequestForm {
		return this._userRequestForm;
	}

	/**
	* Get current legal documents to accept
	*/
	getLegalDocumentToAccept() {
		return this._legalDocumentToAccept;
	}

	/**
	* Set current legal documents to accept
	*/
	setLegalDocumentToAccept(docs: LegalDocument[], countryCode: string) {
		this._legalDocumentToAccept = docs;
		this._legalDocCountryCode = countryCode;
	}

	/**
	* Get yes label according current country code
	*/
	getYesItem(): string {
		return YES_ITEMS.get(`Yes_${this._legalDocCountryCode?.toLowerCase()}`) ?? YES_ITEMS.get('Yes_en');
	}

	/**
	* Get no label according current country code
	*/
	getNoItem(): string {
		return NO_ITEMS.get(`No_${this._legalDocCountryCode?.toLowerCase()}`) ?? NO_ITEMS.get('No_en');
	}

	/**
	* Handle user request after form submitting
	*/
	handleFormSubmit(requestForm: UserRequestForm): void {
		this._userRequestForm = requestForm;
		this._userRequest = this.createUserRequest(this._userRequestForm);
		this.getLegalDocuments(this._userRequest.countryCode, this._userRequest.roleInformationType).subscribe({
			next: legalDocs => {
				if (legalDocs?.length) {
					this._legalDocumentToAccept = legalDocs;
					this._legalDocCountryCode = this._userRequest.countryCode;
					this.router.navigate(['/legalTerms']);
				} else {
					this._handleUserRequest();
				}
			}, error: () => this.toastSrv.showError(this.langSrv.getLabel('USER_REQUEST_ERROR_MSG'))
		});
	}

	/**
	* Handle legal documents acceptance
	*/
	handleLegalDocAcceptance(legalDocAcceptance: LegalDocumentAcceptance[], isAcceptance = false): void {
		if (isAcceptance) {
			this._handleLegalDocsAcceptance(legalDocAcceptance);
		} else {
			this._userRequest.legalDocumentAccepted = legalDocAcceptance;
			this._handleUserRequest();
		}
	}

	private _handleUserRequest(): void {
		this.recaptchaV3Srv.execute(this.CAPTCHA_ACTION_REQUEST).pipe(
			switchMap(token => this.sendRequest(token))
		).subscribe({
			next: () => {
				this.resetRequest();
				this._successState.next(true);
				this.router.navigateByUrl('/success');
			},
			error: (err: Error) => {
				if (err?.message === "UserNameNotUnique") {
					this.toastSrv.showError(this.langSrv.getLabel('USER_REQUEST_ERROR_EMAIL'));
					this._emailExistError.next(true);
				} else if (err?.message === "TokenNotValid") {
					this.toastSrv.showError(this.langSrv.getLabel('USER_REQUEST_ERROR_TOKEN'));
				} else {
					this.toastSrv.showError(this.langSrv.getLabel('USER_REQUEST_ERROR_MSG'));
				}
			}
		});
	}

	private _handleLegalDocsAcceptance(legalDocAcceptance: LegalDocumentAcceptance[]): void {
		this.loaderSrv.show();
		this.setDocumentsApproval(legalDocAcceptance).subscribe({
			next: () => {
				this.toastSrv.showSuccess(this.langSrv.getLabel('USER_REQUEST_SUCCESS_MSG'));
				setTimeout(() => window.location.href = environment.patplanSite, 3000);
			},
			error: (err: Error) => {
				this.loaderSrv.hide();
				if (err?.message === "TokenNotValid") {
					this.toastSrv.showError(this.langSrv.getLabel('USER_REQUEST_ERROR_TOKEN'));
				} else {
					this.toastSrv.showError(this.langSrv.getLabel('USER_REQUEST_ERROR_MSG'));
				}
			}
		});
	}

	/**
	* Get legal documents to accept 
	*/
	getLegalDocuments(countryCode: string, roleType: string): Observable<LegalDocument[]> {
		return this.userRepo.getLegalDocuments(countryCode, roleType).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	/**
	 * Approve legal documents 
	 */
	private setDocumentsApproval(legalDocsAccepted: LegalDocumentAcceptance[]): Observable<any> {
		return this.recaptchaV3Srv.execute(this.CAPTCHA_ACTION_ACCEPTANCE).pipe(
			switchMap(token => this.userRepo.setDocumentsApproval(legalDocsAccepted, token)),
			map(res => this.handleApiResponse(res))
		);
	}

	private sendRequest(captchaToken: string): Observable<any> {
		return this.userRepo.sendRequest(this._userRequest, captchaToken).pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	private resetRequest(): void {
		this._userRequestForm = null;
		this._userRequest = null;
		this._legalDocumentToAccept = null;
		this._legalDocCountryCode = null;
	}

	private createUserRequest(form: UserRequestForm): UserRequest {
		let practice: Practice;
		if (form.practiceType != null) {
			let licenseList: UsStateLicense[] = [];
			for (let i = 1; i <= 5; i++) {
				if (form['stateCode' + i] != null) {
					let license: UsStateLicense = { stateCode: form['stateCode' + i], licenseNumber: form['licenseNumber' + i] };
					licenseList.push(license);
				} else {
					break;
				}
			}
			practice = {
				practiceType: form.practiceType,
				npi: form.npi,
				stateLicenses: licenseList
			}

		}
		return {
			email: form.email,
			confirmEmail: form.confirmEmail,
			firstName: form.firstName,
			lastName: form.lastName,
			dateOfBirth: new Date(form.dateOfBirth),
			countryCode: form.country != null ? form.country.code : null,
			roleInformationType: form.roleType == RoleTypeEnum.HCP ? SURGEON_CODE : form.otherRole,
			practice: practice,
			distributor: form.distributor,
			hospitalOrCompany: this.getHospitalOrCompany(form),
			address: encodeURI(form.address),
			city: form.city,
			stateProvince: form.country != null && form.country.code == USA_CODE ? form.usStateProvince.name : form.stateProvince,
			postalCode: form.postalCode,
			officePhone: form.officePhone,
			mobilePhone: form.mobilePhone,
			legalDocumentAccepted: null
		};
	}

	private getHospitalOrCompany(form: UserRequestForm): string {
		if (form.hospital != null) {
			return form.hospital instanceof Object ? form.hospital.poi.name : form.hospital;
		} else if (form.company != null) {
			return encodeURI(form.company instanceof Object ? form.company.poi.name : form.company);
		}
		return null;
	}

	/**
	 * Get user profile data.
	 */
	getUserProfile(): Observable<UserProfile> {
		return this.userRepo.getUserProfile().pipe(
			map(res => this.handleApiResponse(res))
		);
	}

	private handleApiResponse<T>(response: ApiResponse<T>): T {
		switch (response.statusCode) {
			case ApiResponseStatusCode.Success: return response.result;
			case ApiResponseStatusCode.UserNameNotUnique: throw new Error("UserNameNotUnique");
			case ApiResponseStatusCode.TokenNotValid: throw new Error("TokenNotValid");
			default: throw new Error("Generic error");
		}
	}

}
