import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LanguageService, MonitoringService } from './core';
import { BaseComponent } from './shared';


/**
* Root application component
*/
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(
    private monitoringService: MonitoringService,
    private titleService: Title,
    private langSrv: LanguageService) {
    super(langSrv);
  }

  ngOnInit() {
    this.titleService.setTitle(this.labels.APP_MAIN_TITLE);
  }
}
