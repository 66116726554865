<!-- Main container -->
<div class="container-fluid">

	<!-- First row wrap Breadcrumb + Title + Alert -->
	<div class="row row-header">
		<div class="col">

			<!-- Breadcrumbs -->
			<div class="breadcrumbs">
				<span class="welcome-text" *ngIf="envName != 'production'">{{ buildVer }}</span>
			</div>
			<!-- Logo full -->
			<div class="logo-full-text">{{labels.APP_LOGO_TEXT}}</div>

			<!-- Page title -->
			<h1>{{ labels.ACCOUNT_COMPONENT_HEADER }}</h1>

		</div>
	</div>

	<form [formGroup]="reqForm">

		<div class="row row-contents">

			<div class="col-8">

				<div class="panel info">
					{{ labels.ACCOUNT_COMPONENT_INFO_TEXT }}
				</div>

			</div>
			<div class="col-4"></div>

			<div class="col-lg-3">

				<div class="form-group" id="email">
					<label for="email">{{ labels.ACCOUNT_COMPONENT_EMAIL }} *</label>
					<input type="email" class="form-control" id="email" placeholder="{{ labels.ACCOUNT_COMPONENT_EMAIL }}"
						formControlName="email" (change)="compareConfirmEmail()"
						[ngClass]="{'is-valid': reqForm.controls.email.valid && reqForm.controls.email.touched, 'is-invalid': reqForm.controls.email.invalid && reqForm.controls.email.touched}">
					<div class="invalid-feedback" *ngIf="reqForm.controls.email.invalid">
						{{reqForm.controls.email["errorMessage"]}}</div>
				</div>

			</div>

			<div class="col-lg-3">

				<div class="form-group" id="confirm-email">
					<label for="ConfirmEmail">{{ labels.ACCOUNT_COMPONENT_CONFIRM_EMAIL }} *</label>
					<input type="email" class="form-control" id="ConfirmEmail"
						placeholder="{{ labels.ACCOUNT_COMPONENT_CONFIRM_EMAIL }}" formControlName="confirmEmail"
						(change)="compareConfirmEmail()"
						[ngClass]="{'is-valid': reqForm.controls.confirmEmail.valid && reqForm.controls.confirmEmail.touched, 'is-invalid': reqForm.controls.confirmEmail.invalid && reqForm.controls.confirmEmail.touched}">
					<div class="invalid-feedback" *ngIf="reqForm.controls.confirmEmail.invalid">
						{{reqForm.controls.confirmEmail["errorMessage"]}}</div>
				</div>

			</div>

			<div class="col-lg-6">

				<div class="form-group" id="role-type"
					[ngClass]="{'is-valid': reqForm.controls.roleType.valid, 'is-invalid': reqForm.controls.roleType.invalid && reqForm.controls.roleType.touched}">
					<label for="roleType">{{ labels.ACCOUNT_COMPONENT_ROLE_TYPE }} *</label>
					<div class="custom-control custom-radio custom-control-inline">
						<input type="radio" id="roleType-1" name="roleType" class="custom-control-input" [value]="HCP"
							formControlName="roleType" (ngModelChange)="updateRoleType()"
							[ngClass]="{'is-valid': reqForm.controls.roleType.valid && reqForm.controls.roleType.touched, 'is-invalid': reqForm.controls.roleType.invalid && reqForm.controls.roleType.touched}">
						<label class="custom-control-label" for="roleType-1">{{ labels.ACCOUNT_COMPONENT_HCP }}</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input type="radio" id="roleType-2" name="roleType" class="custom-control-input" [value]="OTHERS"
							formControlName="roleType" (ngModelChange)="updateRoleType()"
							[ngClass]="{'is-valid': reqForm.controls.roleType.valid && reqForm.controls.roleType.touched, 'is-invalid': reqForm.controls.roleType.invalid && reqForm.controls.roleType.touched}">
						<label class="custom-control-label" for="roleType-2">{{ labels.ACCOUNT_COMPONENT_OTHERS }}</label>
					</div>
					<div class="invalid-feedback" *ngIf="reqForm.controls.roleType.invalid">
						{{reqForm.controls.roleType["errorMessage"]}}</div>
				</div>

			</div>


			<ng-container *ngIf="isFormDetailsVisible">

				<div class="divider"></div> <!-- Divider -->

				<div class="col-lg-3">

					<div class="form-group" id="first-name">
						<label for="firstName">{{ labels.ACCOUNT_COMPONENT_FIRST_NAME }} *</label>
						<input type="text" autocomplete="nope" class="form-control" id="firstName"
							placeholder="{{ labels.ACCOUNT_COMPONENT_FIRST_NAME }}" formControlName="firstName"
							[ngClass]="{'is-valid': reqForm.controls.firstName.valid && reqForm.controls.firstName.touched, 'is-invalid': reqForm.controls.firstName.invalid && reqForm.controls.firstName.touched}">
						<div class="invalid-feedback" *ngIf="reqForm.controls.firstName.invalid">
							{{reqForm.controls.firstName["errorMessage"]}}</div>
					</div>

				</div>

				<div class="col-lg-3">

					<div class="form-group" id="last-name">
						<label for="lastName">{{ labels.ACCOUNT_COMPONENT_LAST_NAME }} *</label>
						<input type="text" autocomplete="nope" class="form-control" id="lastName"
							placeholder="{{ labels.ACCOUNT_COMPONENT_LAST_NAME }}" formControlName="lastName"
							[ngClass]="{'is-valid': reqForm.controls.lastName.valid && reqForm.controls.lastName.touched, 'is-invalid': reqForm.controls.lastName.invalid && reqForm.controls.lastName.touched}">
						<div class="invalid-feedback" *ngIf="reqForm.controls.lastName.invalid">
							{{reqForm.controls.lastName["errorMessage"]}}</div>
					</div>

				</div>

				<div class="col-lg-3">

					<div class="form-group">
						<label for="dateOfBirth">{{ labels.ACCOUNT_COMPONENT_DATE_OF_BIRTH }} *</label>
						<div class="input-group wrapper-calendar" #dateOfBirthTarget
							[ngClass]="{'is-valid': reqForm.controls.dateOfBirth.valid && reqForm.controls.dateOfBirth.touched, 'is-invalid': reqForm.controls.dateOfBirth.invalid && reqForm.controls.dateOfBirth.touched }">
							<i class="material-icons input-group-append icon-calendar" (click)="dateOfBirthPicker.toggle()">today</i>
							<input type="text" autocomplete="nope" class="form-control" id="dateOfBirth"
								placeholder="{{ labels.ACCOUNT_COMPONENT_DATE_OF_BIRTH }}" formControlName="dateOfBirthFormat"
								(focus)="reqForm.controls.dateOfBirth.markAsTouched()"
								[ngClass]="{'is-valid': reqForm.controls.dateOfBirth.valid && reqForm.controls.dateOfBirth.touched, 'is-invalid': reqForm.controls.dateOfBirth.invalid && reqForm.controls.dateOfBirth.touched }"
								readonly style="color: #fff" />
							<i class="material-icons input-group-append align-self-center md-18"
								(click)="clearDateOfBirth()">clear</i>
						</div>
						<div class="invalid-feedback" *ngIf="reqForm.controls.dateOfBirth.invalid">
							{{reqForm.controls.dateOfBirth["errorMessage"]}}</div>
					</div>
					<datepicker #dateOfBirthPicker [value]="reqForm.value.dateOfBirth" (dateChanged)="updateDateOfBirth($event)"
						[positionTarget]="dateOfBirthTarget" placement="bottom-right" [maxDate]="maxDateOfBirth">
					</datepicker>

				</div>

				<div class="col-lg-3">
				</div>

				<div class="col-lg-3" *ngIf="isHospitalVisible">

					<div class="form-group" id="hospital">
						<label for="hospital">{{ labels.ACCOUNT_COMPONENT_HOSPITAL }} *</label>
						<input type="text" autocomplete="nope" class="form-control" id="hospital"
							placeholder="{{ labels.ACCOUNT_COMPONENT_HOSPITAL }}" formControlName="hospital"
							(ngModelChange)="updateHospital()" [ngbTypeahead]="mapsSrv.searchHospitals" [focusFirst]="false"
							[inputFormatter]="hospitalFormatter" [resultFormatter]="hospitalFormatter"
							(selectItem)="selectPOI($event)"
							[ngClass]="{'is-valid': reqForm.controls.hospital.valid && reqForm.controls.hospital.touched, 'is-invalid': reqForm.controls.hospital.invalid && reqForm.controls.hospital.touched}">
						<div class="invalid-feedback" *ngIf="reqForm.controls.hospital.invalid">
							{{reqForm.controls.hospital["errorMessage"]}}</div>
					</div>

				</div>

				<div class="col-lg-3" *ngIf="isDistributorVisible">

					<div class="form-group" id="distributor">
						<label for="distributor">{{ labels.ACCOUNT_COMPONENT_DISTRIBUTOR }} *</label>
						<input type="text" autocomplete="nope" class="form-control" id="distributor"
							placeholder="{{ labels.ACCOUNT_COMPONENT_DISTRIBUTOR }}" formControlName="distributor"
							[ngClass]="{'is-valid': reqForm.controls.distributor.valid && reqForm.controls.distributor.touched, 'is-invalid': reqForm.controls.distributor.invalid && reqForm.controls.distributor.touched}">
						<div class="invalid-feedback" *ngIf="reqForm.controls.distributor.invalid">
							{{reqForm.controls.distributor["errorMessage"]}}</div>
					</div>

				</div>

				<div class="col-lg-3" *ngIf="isOtherRoleVisible">

					<div class="form-group" id="other-role">
						<label for="otherRole">{{ labels.ACCOUNT_COMPONENT_ROLE }} *</label>
						<select class="form-control custom-select" id="otherRole" autocomplete="nope" formControlName="otherRole"
							[ngClass]="{'is-valid': reqForm.controls.otherRole.valid && reqForm.controls.otherRole.touched, 'is-invalid': reqForm.controls.otherRole.invalid && reqForm.controls.otherRole.touched}">
							<option [ngValue]="null">{{ labels.ACCOUNT_COMPONENT_SELECT_ROLE }}</option>
							<option *ngFor="let role of allOtherRoles" [ngValue]="role.value">{{ role.value }}</option>
						</select>
						<div class="invalid-feedback" *ngIf="reqForm.controls.otherRole.invalid">
							{{ reqForm.controls.otherRole["errorMessage"] }}</div>
					</div>

				</div>

				<div class="col-lg-3" *ngIf="isCompanyVisible">

					<div class="form-group" id="company">
						<label for="company">{{ labels.ACCOUNT_COMPONENT_COMPANY }} *</label>
						<input type="text" autocomplete="nope" class="form-control" id="company"
							placeholder="{{ labels.ACCOUNT_COMPONENT_COMPANY }}" formControlName="company"
							(ngModelChange)="updateCompany()" [ngbTypeahead]="mapsSrv.searchCompanies"
							[inputFormatter]="companyFormatter" [resultFormatter]="companyFormatter" (selectItem)="selectPOI($event)"
							[ngClass]="{'is-valid': reqForm.controls.company.valid && reqForm.controls.company.touched, 'is-invalid': reqForm.controls.company.invalid && reqForm.controls.company.touched}">
						<div class="invalid-feedback" *ngIf="reqForm.controls.company.invalid">
							{{reqForm.controls.company["errorMessage"]}}</div>
					</div>

				</div>

				<div class="divider"></div> <!-- Divider -->

				<div class="col-lg-6">

					<div class="row">

						<div class="col-6">
							<div class="form-group" id="country">
								<label for="country">{{ labels.ACCOUNT_COMPONENT_COUNTRY }} *</label>
								<select class="form-control custom-select" id="country" autocomplete="nope" formControlName="country"
									[compareWith]="compareCountry" (ngModelChange)="updateCountry()"
									[ngClass]="{'is-valid': reqForm.controls.country.valid && reqForm.controls.country.touched, 'is-invalid': reqForm.controls.country.invalid && reqForm.controls.country.touched}">
									<option [ngValue]="null">{{ labels.ACCOUNT_COMPONENT_SELECT_COUNTRY }}</option>
									<option *ngFor="let country of allCountries" [ngValue]="country">{{ country.name }}</option>
								</select>
								<div class="invalid-feedback" *ngIf="reqForm.controls.country.invalid">
									{{ reqForm.controls.country["errorMessage"] }}</div>
							</div>
						</div>

						<div class="col-6">
							<div class="form-group" id="address">
								<label for="address">{{ labels.ACCOUNT_COMPONENT_ADDRESS }} *</label>
								<input type="text" autocomplete="nope" class="form-control" id="address"
									placeholder="{{ labels.ACCOUNT_COMPONENT_ADDRESS }}" formControlName="address"
									[ngClass]="{'is-valid': reqForm.controls.address.valid && reqForm.controls.address.touched, 'is-invalid': reqForm.controls.address.invalid && reqForm.controls.address.touched}">
								<div class="invalid-feedback" *ngIf="reqForm.controls.address.invalid">
									{{reqForm.controls.address["errorMessage"]}}</div>
							</div>
						</div>

						<div class="col-6">
							<div class="form-group" id="state-province" *ngIf="isUSStateVisible; else elseBlock">
								<label for="stateProvince">{{ labels.ACCOUNT_COMPONENT_PROVINCE }} *</label>
								<select class="form-control custom-select" id="stateProvince" autocomplete="nope"
									formControlName="usStateProvince"
									[ngClass]="{'is-valid': reqForm.controls.usStateProvince.valid && reqForm.controls.usStateProvince.touched, 'is-invalid': reqForm.controls.usStateProvince.invalid && reqForm.controls.usStateProvince.touched}">
									<option [ngValue]="null">{{ labels.ACCOUNT_COMPONENT_SELECT_STATE }}</option>
									<option *ngFor="let state of allUSStates" [ngValue]="state">{{ state.name }}</option>
								</select>
								<div class="invalid-feedback" *ngIf="reqForm.controls.usStateProvince.invalid">
									{{reqForm.controls.usStateProvince["errorMessage"]}}</div>
							</div>
							<ng-template #elseBlock>
								<div class="form-group" id="stateProvince">
									<label for="stateProvince">{{ labels.ACCOUNT_COMPONENT_PROVINCE }} *</label>
									<input type="text" autocomplete="nope" class="form-control" id="stateProvince"
										placeholder="{{ labels.ACCOUNT_COMPONENT_PROVINCE }}" formControlName="stateProvince"
										[ngClass]="{'is-valid': reqForm.controls.stateProvince.valid && reqForm.controls.stateProvince.touched, 'is-invalid': reqForm.controls.stateProvince.invalid && reqForm.controls.stateProvince.touched}">
									<div class="invalid-feedback" *ngIf="reqForm.controls.stateProvince.invalid">
										{{reqForm.controls.stateProvince["errorMessage"]}}</div>
								</div>
							</ng-template>
						</div>

						<div class="col-6">
							<div class="form-group" id="city">
								<label for="city">{{ labels.ACCOUNT_COMPONENT_CITY }} *</label>
								<input type="text" autocomplete="nope" class="form-control" id="city"
									placeholder="{{ labels.ACCOUNT_COMPONENT_CITY }}" formControlName="city"
									[ngClass]="{'is-valid': reqForm.controls.city.valid && reqForm.controls.city.touched, 'is-invalid': reqForm.controls.city.invalid && reqForm.controls.city.touched}">
								<div class="invalid-feedback" *ngIf="reqForm.controls.city.invalid">
									{{reqForm.controls.city["errorMessage"]}}</div>
							</div>
						</div>

						<div class="col-6">
							<div class="form-group" id="postal-code">
								<label for="postalCode">{{ labels.ACCOUNT_COMPONENT_POSTAL_CODE }} *</label>
								<input type="text" autocomplete="nope" class="form-control" id="postalCode"
									placeholder="{{ labels.ACCOUNT_COMPONENT_POSTAL_CODE }}" formControlName="postalCode"
									[ngClass]="{'is-valid': reqForm.controls.postalCode.valid && reqForm.controls.postalCode.touched, 'is-invalid': reqForm.controls.postalCode.invalid && reqForm.controls.postalCode.touched}">
								<div class="invalid-feedback" *ngIf="reqForm.controls.postalCode.invalid">
									{{reqForm.controls.postalCode["errorMessage"]}}</div>
							</div>
						</div>

						<div class="col-6">
							<div class="form-group" id="office-phone">
								<label for="officePhone">{{ labels.ACCOUNT_COMPONENT_PHONE }} *</label>
								<input type="text" autocomplete="nope" class="form-control" id="officePhone"
									placeholder="{{ labels.ACCOUNT_COMPONENT_PHONE }}" formControlName="officePhone"
									[ngClass]="{'is-valid': reqForm.controls.officePhone.valid && reqForm.controls.officePhone.touched, 'is-invalid': reqForm.controls.officePhone.invalid && reqForm.controls.officePhone.touched}">
								<div class="invalid-feedback" *ngIf="reqForm.controls.officePhone.invalid">
									{{reqForm.controls.officePhone["errorMessage"]}}</div>
							</div>
						</div>

						<div class="col-6">
							<div class="form-group" id="mobile-phone">
								<label for="mobilePhone">{{ labels.ACCOUNT_COMPONENT_MOBILE }}</label>
								<input type="text" autocomplete="nope" class="form-control" id="mobilePhone"
									placeholder="{{ labels.ACCOUNT_COMPONENT_MOBILE }}" formControlName="mobilePhone"
									[ngClass]="{'is-valid': reqForm.controls.mobilePhone.valid && reqForm.controls.mobilePhone.touched, 'is-invalid': reqForm.controls.mobilePhone.invalid && reqForm.controls.mobilePhone.touched}">
								<div class="invalid-feedback" *ngIf="reqForm.controls.mobilePhone.invalid">
									{{reqForm.controls.mobilePhone["errorMessage"]}}</div>
							</div>
						</div>

					</div>

				</div>


				<div class="col-lg-6">
					<div id="map" class="user-request-map" [hidden]="!isMapVisible">
						<app-map [position]="hospitalPosition"></app-map>
					</div>
				</div>

				<div class="divider"></div> <!-- Divider -->


				<div class="col-lg-2" *ngIf="isPracticeTypeVisible">

					<div class="form-group" id="practice-type">
						<label for="practiceType">{{ labels.ACCOUNT_COMPONENT_PRACTICE_TYPE }} *</label>
						<select class="form-control custom-select" id="practiceType" autocomplete="nope"
							formControlName="practiceType" (ngModelChange)="updatePracticeType()"
							[ngClass]="{'is-valid': reqForm.controls.practiceType.valid && reqForm.controls.practiceType.touched, 'is-invalid': reqForm.controls.practiceType.invalid && reqForm.controls.practiceType.touched}">
							<option [ngValue]="null">{{ labels.ACCOUNT_COMPONENT_SELECT_PRACTICE_TYPE }}</option>
							<option *ngFor="let type of allPracticeTypes" [ngValue]="type.value"> {{ type.value }} </option>
						</select>
						<div class="invalid-feedback" *ngIf="reqForm.controls.practiceType.invalid">
							{{ reqForm.controls.practiceType["errorMessage"] }}</div>
					</div>

				</div>

				<div class="col-lg-2" *ngIf="isNpiVisible">

					<div class="form-group" id="npi">
						<label for="npi">{{ labels.ACCOUNT_COMPONENT_NPI }} *</label>
						<input type="text" autocomplete="nope" class="form-control" id="npi"
							placeholder="{{ labels.ACCOUNT_COMPONENT_NPI }}" formControlName="npi"
							[ngClass]="{'is-valid': reqForm.controls.npi.valid && reqForm.controls.npi.touched, 'is-invalid': reqForm.controls.npi.invalid && reqForm.controls.npi.touched}">
						<div class="invalid-feedback" *ngIf="reqForm.controls.npi.invalid">{{reqForm.controls.npi["errorMessage"]}}
						</div>
					</div>

				</div>

				<div class="col-lg-4" id="state-license-1" *ngIf="isStateLicense1Visible">

					<label for="stateLicense1">{{ labels.ACCOUNT_COMPONENT_STATE_LICENSE_1 }} *</label>
					<div class="multirow-input">
						<div class="form-group">
							<select class="form-control custom-select" id="stateLicense1" autocomplete="nope"
								formControlName="stateCode1" (change)="updateStateCode(1)"
								[ngClass]="{'is-valid': reqForm.controls.stateCode1.valid && reqForm.controls.stateCode1.touched, 'is-invalid': reqForm.controls.stateCode1.invalid && reqForm.controls.stateCode1.touched}">
								<option [ngValue]="null">-</option>
								<option *ngFor="let state of allUSStates" [ngValue]="state.code"> {{ state.code }} </option>
							</select>
							<div class="invalid-feedback" *ngIf="reqForm.controls.stateCode1.invalid">
								{{reqForm.controls.stateCode1["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<input type="text" autocomplete="nope" class="form-control" id="licenseNumber1"
								placeholder="{{ labels.ACCOUNT_COMPONENT_LICENSE_NUMBER }}" formControlName="licenseNumber1"
								(change)="updateLicenseNumber(1)"
								[ngClass]="{'is-valid': reqForm.controls.licenseNumber1.valid && reqForm.controls.licenseNumber1.touched, 'is-invalid': reqForm.controls.licenseNumber1.invalid && reqForm.controls.licenseNumber1.touched}">
							<div class="invalid-feedback" *ngIf="reqForm.controls.licenseNumber1.invalid">
								{{reqForm.controls.licenseNumber1["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<div><i class="material-icons" (click)="isStateLicense2Visible = true"
									*ngIf="!isStateLicense2Visible">add</i></div>
						</div>
					</div>

				</div>

				<div class="col-lg-4" id="state-license-2" *ngIf="isStateLicense2Visible">

					<label for="stateLicense2">{{ labels.ACCOUNT_COMPONENT_STATE_LICENSE_2 }}</label>
					<div class="multirow-input">
						<div class="form-group">
							<select class="form-control custom-select" id="stateLicense2" autocomplete="nope"
								formControlName="stateCode2" (change)="updateStateCode(2)"
								[ngClass]="{'is-valid': reqForm.controls.stateCode2.valid && reqForm.controls.stateCode2.touched, 'is-invalid': reqForm.controls.stateCode2.invalid && reqForm.controls.stateCode2.touched}">
								<option [ngValue]="null">-</option>
								<option *ngFor="let state of allUSStates" [ngValue]="state.code"> {{ state.code }} </option>
							</select>
							<div class="invalid-feedback" *ngIf="reqForm.controls.stateCode2.invalid">
								{{reqForm.controls.stateCode2["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<input type="text" autocomplete="nope" class="form-control" id="licenseNumber2"
								placeholder="{{ labels.ACCOUNT_COMPONENT_LICENSE_NUMBER }}" formControlName="licenseNumber2"
								(change)="updateLicenseNumber(2)"
								[ngClass]="{'is-valid': reqForm.controls.licenseNumber2.valid && reqForm.controls.licenseNumber2.touched, 'is-invalid': reqForm.controls.licenseNumber2.invalid && reqForm.controls.licenseNumber2.touched}">
							<div class="invalid-feedback" *ngIf="reqForm.controls.licenseNumber2.invalid">
								{{reqForm.controls.licenseNumber2["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<div><i class="material-icons" (click)="removeStateLicense(2)" *ngIf="!isStateLicense3Visible">delete</i>
							</div>
							<div><i class="material-icons" (click)="isStateLicense3Visible = true"
									*ngIf="!isStateLicense3Visible">add</i></div>
						</div>
					</div>

				</div>

				<div class="col-lg-4" id="state-license-3" *ngIf="isStateLicense3Visible">

					<label for="stateLicense3">{{ labels.ACCOUNT_COMPONENT_STATE_LICENSE_3 }}</label>
					<div class="multirow-input">
						<div class="form-group">
							<select class="form-control custom-select" id="stateLicense3" autocomplete="nope"
								formControlName="stateCode3" (change)="updateStateCode(3)"
								[ngClass]="{'is-valid': reqForm.controls.stateCode3.valid && reqForm.controls.stateCode3.touched, 'is-invalid': reqForm.controls.stateCode3.invalid && reqForm.controls.stateCode3.touched}">
								<option [ngValue]="null">-</option>
								<option *ngFor="let state of allUSStates" [ngValue]="state.code"> {{ state.code }} </option>
							</select>
							<div class="invalid-feedback" *ngIf="reqForm.controls.stateCode3.invalid">
								{{reqForm.controls.stateCode3["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<input type="text" autocomplete="nope" class="form-control" id="licenseNumber3"
								placeholder="{{ labels.ACCOUNT_COMPONENT_LICENSE_NUMBER }}" formControlName="licenseNumber3"
								(change)="updateLicenseNumber(3)"
								[ngClass]="{'is-valid': reqForm.controls.licenseNumber3.valid && reqForm.controls.licenseNumber3.touched, 'is-invalid': reqForm.controls.licenseNumber3.invalid && reqForm.controls.licenseNumber3.touched}">
							<div class="invalid-feedback" *ngIf="reqForm.controls.licenseNumber3.invalid">
								{{reqForm.controls.licenseNumber3["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<div><i class="material-icons" (click)="removeStateLicense(3)" *ngIf="!isStateLicense4Visible">delete</i>
							</div>
							<div><i class="material-icons" (click)="isStateLicense4Visible = true"
									*ngIf="!isStateLicense4Visible">add</i></div>
						</div>
					</div>

				</div>

				<div class="col-lg-4" id="state-license-4" *ngIf="isStateLicense4Visible">

					<label for="stateLicense4">{{ labels.ACCOUNT_COMPONENT_STATE_LICENSE_4 }}</label>
					<div class="multirow-input">
						<div class="form-group">
							<select class="form-control custom-select" id="stateLicense4" autocomplete="nope"
								formControlName="stateCode4" (change)="updateStateCode(4)"
								[ngClass]="{'is-valid': reqForm.controls.stateCode4.valid && reqForm.controls.stateCode4.touched, 'is-invalid': reqForm.controls.stateCode4.invalid && reqForm.controls.stateCode4.touched}">
								<option [ngValue]="null">-</option>
								<option *ngFor="let state of allUSStates" [ngValue]="state.code"> {{ state.code }} </option>
							</select>
							<div class="invalid-feedback" *ngIf="reqForm.controls.stateCode4.invalid">
								{{reqForm.controls.stateCode4["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<input type="text" autocomplete="nope" class="form-control" id="licenseNumber4"
								placeholder="{{ labels.ACCOUNT_COMPONENT_LICENSE_NUMBER }}" formControlName="licenseNumber4"
								(change)="updateLicenseNumber(4)"
								[ngClass]="{'is-valid': reqForm.controls.licenseNumber4.valid && reqForm.controls.licenseNumber4.touched, 'is-invalid': reqForm.controls.licenseNumber4.invalid && reqForm.controls.licenseNumber4.touched}">
							<div class="invalid-feedback" *ngIf="reqForm.controls.licenseNumber4.invalid">
								{{reqForm.controls.licenseNumber4["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<div><i class="material-icons" (click)="removeStateLicense(4)" *ngIf="!isStateLicense5Visible">delete</i>
							</div>
							<div><i class="material-icons" (click)="isStateLicense5Visible = true"
									*ngIf="!isStateLicense5Visible">add</i></div>
						</div>
					</div>

				</div>

				<div class="col-lg-4" id="state-license-5" *ngIf="isStateLicense5Visible">

					<label for="stateLicense5">{{ labels.ACCOUNT_COMPONENT_STATE_LICENSE_5 }}</label>
					<div class="multirow-input">
						<div class="form-group">
							<select class="form-control custom-select" id="stateLicense5" autocomplete="nope"
								formControlName="stateCode5" (change)="updateStateCode(5)"
								[ngClass]="{'is-valid': reqForm.controls.stateCode5.valid && reqForm.controls.stateCode5.touched, 'is-invalid': reqForm.controls.stateCode5.invalid && reqForm.controls.stateCode5.touched}">
								<option [ngValue]="null">-</option>
								<option *ngFor="let state of allUSStates" [ngValue]="state.code"> {{ state.code }} </option>
							</select>
							<div class="invalid-feedback" *ngIf="reqForm.controls.stateCode5.invalid">
								{{reqForm.controls.stateCode5["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<input type="text" autocomplete="nope" class="form-control" id="licenseNumber5"
								placeholder="{{ labels.ACCOUNT_COMPONENT_LICENSE_NUMBER }}" formControlName="licenseNumber5"
								(change)="updateLicenseNumber(5)"
								[ngClass]="{'is-valid': reqForm.controls.licenseNumber5.valid && reqForm.controls.licenseNumber5.touched, 'is-invalid': reqForm.controls.licenseNumber5.invalid && reqForm.controls.licenseNumber5.touched}">
							<div class="invalid-feedback" *ngIf="reqForm.controls.licenseNumber5.invalid">
								{{reqForm.controls.licenseNumber5["errorMessage"]}}</div>
						</div>
						<div class="form-group">
							<div><i class="material-icons" (click)="removeStateLicense(5)">delete</i></div>
						</div>
					</div>

				</div>


			</ng-container>

			<div class="col-12">

				<div class="row">

					<div class="col-8">
						<p>{{ labels.ACCOUNT_COMPONENT_LEGAL_SENTENCE }}</p>
						<button type="button" class="btn btn-primary" [disabled]="!reqForm.valid" (click)="onSubmit()">{{
							labels.ACCOUNT_COMPONENT_BUTTON_SEND }}</button>
					</div>

					<div class="col-4"></div>

				</div>

			</div>

		</div>

	</form>

</div>