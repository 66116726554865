/**
* Model of generic lookup entities
*/
export class Lookup {
	id: number;
	value: string;
	otherInfo?: string;
}

/**
* Legal document type model
*/
export class LegalDocumentType extends Lookup { }

export enum LegalDocumentTypeEnum {
	PrivacyPolicy = "Privacy Policy",
	EULA = "End User License Agreement",
	DPA = "Data Process Agreement"
}

/**
* Document item type model
*/
export class DocumentItemType extends Lookup { }

export enum DocumentItemTypeEnum {
	PP_Acknowledgement = "PP_Acknowledgement",
	PP_I_Consent = "PP_I_Consent",
	PP_I_Deny_Consent = "PP_I_Deny_Consent",
	PP_DirectMarketing = "PP_DirectMarketing",
	PP_UserProfilation = "PP_UserProfilation",
	EULA_I_Accept = "EULA_I_Accept",
	EULA_I_Accept2 = "EULA_I_Accept2",
	EULA_Additional_Msg = "EULA_Additional_Message",
	DPA_Acknowledgement = "DPA_Acknowledgement",
	DPA_I_Consent = "DPA_I_Consent",
	DPA_I_Deny_Consent = "DPA_I_Deny_Consent"
}

/**
* Country model
*/
export class Country {
	id: number;
	name: string;
	code: string;
}

/**
* Mapper from Lookup to Country model
*/
export const countryMapper = (lookup: Lookup): Country => {
	return {
		id: lookup.id,
		name: lookup.value,
		code: lookup.otherInfo
	}
}

/**
* Unites States model
*/
export class USState {
	id: number;
	name: string;
	code: string;
}

/**
* Mapper from Lookup to USState model
*/
export const usStateMapper = (lookup: Lookup): USState => {
	return {
		id: lookup.id,
		name: lookup.otherInfo,
		code: lookup.value
	}
}

/**
* Role information type model
*/
export class RoleInformationType extends Lookup { }

/**
* Practice type model
*/
export class PracticeType extends Lookup { }

/**
* United States country code
*/
export const USA_CODE: string = "US";

/**
* Surgeon role type code
*/
export const SURGEON_CODE: string = "Surgeon";

/**
* Role information type enumeration
*/
export enum RoleTypeEnum {
	HCP = "HCP",
	OTHERS = "OTHERS"
}

/**
* Dictionary names enumeration
*/
export enum DictionaryEnum {
	COUNTRY = "Country",
	ROLE_INFORMATION_TYPE = "RoleInformationType",
	PRACTICE_TYPE = "PracticeType",
	US_STATE = "USState",
	LEGAL_DOCUMENT_TYPE = "LegalDocumentType",
	DOCUMENT_ITEM_TYPE = "DocumentItemType"
}

/**
* List of dictionaries names for user request
*/
export const USER_REQUEST_DICTIONARY_LIST: string = DictionaryEnum.COUNTRY + "," + DictionaryEnum.ROLE_INFORMATION_TYPE + "," + DictionaryEnum.PRACTICE_TYPE + "," + DictionaryEnum.US_STATE + "," + DictionaryEnum.LEGAL_DOCUMENT_TYPE + "," + DictionaryEnum.DOCUMENT_ITEM_TYPE;

/**
* Country dictionary name
*/
export const COUNTRY_DICTIONARY: string = DictionaryEnum.COUNTRY;
