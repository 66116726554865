import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthCoreModule, AuthService } from '@ortho-next/auth-core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AUTH_CONFIG } from './app.config';
import { ErrorsComponent, LanguageService, LoaderService, MonitoringErrorHandler, MonitoringService, ToastService, UserRepository } from './core';
import { LegalTermsComponent } from './legal-terms';
import { LegalTermsAcceptanceComponent } from './legal-terms-acceptance';
import { LegalTermsPageComponent } from './legal-terms-page';
import { MapsService, UserRequestService } from './services';
import { SharedModule } from './shared';
import { UserRequestComponent } from './user-request';

export function initLabels(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.loadLabels();
	}
}

export function initLocale(langSrv: LanguageService) {
	return (): Promise<any> => {
		return langSrv.initLocale(navigator.language);
	};
}
export function initAuth(authSrv: AuthService) {
	return (): Promise<any> => {
		return authSrv.runInitialLoginSequence();
	};
}

@NgModule({
	declarations: [
		AppComponent,
		UserRequestComponent,
		LegalTermsComponent,
		ErrorsComponent,
		LegalTermsPageComponent,
		LegalTermsAcceptanceComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		AppRoutingModule,
		RxReactiveFormsModule,
		NgbTypeaheadModule,
		SharedModule,
		RecaptchaV3Module,
		AuthCoreModule.forRoot(AUTH_CONFIG)
	],
	providers: [
		CookieService,
		MonitoringService,
		{
			provide: ErrorHandler,
			useClass: MonitoringErrorHandler
		},
		LanguageService,
		UserRepository,
		UserRequestService,
		MapsService,
		ToastService,
		LoaderService,
		{
			provide: APP_INITIALIZER, useFactory: initLabels, deps: [LanguageService], multi: true
		},
		{
			provide: APP_INITIALIZER, useFactory: initLocale, deps: [LanguageService], multi: true
		},
		{
			provide: LOCALE_ID,
			useFactory: (langService: LanguageService) => langService.getCurrentLocale(),
			deps: [LanguageService]
		},
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
		{ provide: APP_INITIALIZER, useFactory: initAuth, deps: [AuthService], multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
