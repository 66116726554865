<div class="container-fluid container-main-nav main-success-page">
  <div class="row">
    <div class="col-12">
      <h1>{{ labels.SUCCESS_PAGE_COMPONENT_MAIN_MSG }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="box-success">
        <p>{{ labels.SUCCESS_PAGE_COMPONENT_SUB_MSG_1 }}</p>
        <p>{{ labels.SUCCESS_PAGE_COMPONENT_SUB_MSG_2 }}</p>
      </div>
    </div>
  </div>

  <div class="row mt-5">

    <div class="col-6">

      <div class="row d-flex justify-content-center mb-5">
        <div class="circle-icon">
          <span class="material-icons-outlined">file_copy</span>
        </div>
      </div>
      <p><strong>{{ labels.SUCCESS_PAGE_COMPONENT_RESOURCES }}</strong></p>
      <p class="mb-0"><strong>{{ labels.SUCCESS_PAGE_COMPONENT_ORTHOFIX_SITE }}</strong> - <em><a href="{{orthofixWebsite}}">{{ labels.SUCCESS_PAGE_COMPONENT_ORTHOFIX_SITE_LINK }}</a></em></p>
      <p class="mb-0"><strong>{{ labels.SUCCESS_PAGE_COMPONENT_ORTHOFIX_EDUCATION }}</strong> - <em><a href="{{orthofixMedicalEducation}}">{{ labels.SUCCESS_PAGE_COMPONENT_ORTHOFIX_EDUCATION_LINK }}</a></em></p>
    </div>

    <div class="col-6">
      <div class="row d-flex justify-content-center mb-5">
        <div class="circle-icon">
          <span class="material-icons-outlined">mail</span>
        </div>
      </div>
      <p><strong>{{ labels.SUCCESS_PAGE_COMPONENT_ASSISTANCE }}</strong></p>
      <a href="mailto:{{customerCareEmail}}" class="btn-primary btn">{{ labels.SUCCESS_PAGE_COMPONENT_CONTACT_US }}</a>
    </div>

  </div>

</div>
