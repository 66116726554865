import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardWithForcedLogin } from '@ortho-next/auth-core';
import { ErrorsComponent, FallbackComponent } from './core';
import { LegalTermsComponent } from './legal-terms';
import { LegalTermsAcceptanceComponent } from './legal-terms-acceptance';
import { LegalTermsPageComponent } from './legal-terms-page';
import { SuccessPageComponent } from './shared';
import { UserRequestComponent } from './user-request';

const routes: Routes = [
	{
		path: 'legal-terms-acceptance',
		component: LegalTermsAcceptanceComponent,
		canActivate: [AuthGuardWithForcedLogin]
	},
	{
		path: 'legal-terms',
		component: LegalTermsPageComponent
	},
	{
		path: 'legalTerms',
		component: LegalTermsComponent
	},
	{
		path: 'userRequest',
		component: UserRequestComponent
	},
	{ path: 'success', component: SuccessPageComponent },
	{ path: 'error', component: ErrorsComponent },
	{
		path: '',
		redirectTo: 'userRequest',
		pathMatch: 'full'
	},
	{ path: '**', component: FallbackComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
