import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './date-parser-formatter';


/**
* Date picker component
*/
@Component({
	selector: 'datepicker',
	templateUrl: './datepicker.component.html',
	providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatepickerComponent implements OnInit {

	@Input() placement: string;
	@Input() positionTarget: string | HTMLElement;
	@Input() maxDate: Date;

	@Input() set value(val: Date | null) {
		this.setValue(val);
	}

	@Output() dateChanged = new EventEmitter<Date>();

	@ViewChild(NgbInputDatepicker, { static: true }) inputDatePicker: NgbInputDatepicker;

	ngbDate: NgbDate | null;

	readonly MIN_DATE: NgbDateStruct = { year: 1900, month: 1, day: 1 }
	MAX_DATE: NgbDateStruct = { year: 2300, month: 1, day: 1 }

	ngOnInit() {
		this.MAX_DATE = this.maxDate ? { year: this.maxDate.getFullYear(), month: this.maxDate.getMonth() + 1, day: this.maxDate.getDate() } : this.MAX_DATE;
	}


	/**
	* Toggle the calendar
	*/
	toggle(): void {
		this.inputDatePicker.toggle();
	}


	private setValue(val: Date | string | null): void {
		if (typeof (val) == 'string') {
			val = new Date(val);
		}
		this.ngbDate = val ? new NgbDate(val.getFullYear(), val.getMonth() + 1, val.getDate()) : null;
	}

	/**
	* Update and emit range selected
	*/
	onDateSelection(date: NgbDate) {
		if (date) {
			const newDate = new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0, 0));
			this.dateChanged.emit(newDate);
		} else {
			this.dateChanged.emit(null);
		}
	}

}
