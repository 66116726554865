    <!-- Main container -->
    <div class="container-fluid">

      <!-- First row wrap Breadcrumb + Title + Alert -->
      <div class="row row-header">
        <div class="col">

          <!-- Breadcrumbs -->
          <div class="breadcrumbs">
            <!--<a><i class="material-icons">arrow_back</i> Previous page</a>-->
          </div>

          <!-- Logo full -->
          <div class="logo-full-text">{{labels.APP_LOGO_TEXT}}</div>

          <!-- Page title -->
          <h1>{{ labels.LEGAL_TERMS_COMPONENT_TITLE }}</h1>
          <p>{{ labels.LEGAL_TERMS_COMPONENT_SUBTITLE }}</p>

        </div>
      </div>

      <form>

        <div class="row row-contents">

          <div class="col-12">

            <div class="swiper-container legal-swiper" style="overflow: hidden;">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper">

                <!-- Slide EndUserLicenseAgreement -->
                <div class="swiper-slide" *ngIf="EULAForm">

                  <p>{{ labels.LEGAL_TERMS_COMPONENT_EFFECTIVE_DATE }}: {{ EULAForm.effectiveDate | date :'shortDate' }}
                  </p>

                  <div class="swiper-slide-content" [innerHTML]="EULAForm.text" (scroll)="onEULAScroll($event)"></div>

                  <div class="form-group switch-custom-container" *ngIf="EULAForm.eula1">
                    <label class="switch">
                      <input #eula1 type="checkbox" [disabled]="!EULAForm.endScroll"
                        (mousedown)="updateStartPosition($event)" (mouseup)="updateEndPosition($event)"
                        (click)="acceptEULA(eula1.checked)" />
                      <span></span>
                    </label>
                    <p>{{ EULAForm.eula1 }}</p>
                  </div>

                  <div *ngIf="EULAForm.add_msg">
                    <p>{{ EULAForm.add_msg }}</p>
                  </div>

                </div>


                <!-- Slide PrivacyPolicy -->
                <div class="swiper-slide" *ngIf="PPForm">

                  <p>{{ labels.LEGAL_TERMS_COMPONENT_EFFECTIVE_DATE }}: {{ PPForm.effectiveDate | date :'shortDate' }}
                  </p>

                  <div class="swiper-slide-content" [innerHTML]="PPForm.text" (scroll)="onPPScroll($event)"></div>

                  <div class="form-group switch-custom-container" *ngIf="PPForm.ack">
                    <label class="switch">
                      <input #privacypolicy type="checkbox" [disabled]="!PPForm.endScroll"
                        (mousedown)="updateStartPosition($event)" (mouseup)="updateEndPosition($event)"
                        (click)="acceptPrivacyPolicy(privacypolicy.checked)" />
                      <span></span>
                    </label>
                    <p>{{ PPForm.ack }}</p>
                  </div>

                  <div class="form-group" *ngIf="PPForm.marketingTxt">
                    <p>{{ PPForm.marketingTxt }}</p>
                    <div class="custom-control custom-radio custom-control-inline" (click)="acceptMarketing(true)">
                      <input type="radio" id="radio-1a" name="radio-1" class="custom-control-input"
                        [value]="!!PPForm.marketingValue" [checked]="!!PPForm.marketingValue"
                        [disabled]="!PPForm.endScroll || !PPForm.isAccepted" />
                      <label class="custom-control-label" for="radio-1a" id="marketing-yes">{{ yesItem }}</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline" (click)="acceptMarketing(false)">
                      <input type="radio" id="radio-1b" name="radio-1" class="custom-control-input"
                        [value]="PPForm.marketingValue === false" [checked]="PPForm.marketingValue === false"
                        [disabled]="!PPForm.endScroll || !PPForm.isAccepted" />
                      <label class="custom-control-label" for="radio-1b" id="marketing-no">{{ noItem }}</label>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="PPForm.profilationTxt">
                    <p>{{ PPForm.profilationTxt }}</p>
                    <div class="custom-control custom-radio custom-control-inline" (click)="acceptProfilation(true)">
                      <input type="radio" id="radio-2a" name="radio-2" class="custom-control-input"
                        [value]="!!PPForm.profilationValue" [checked]="!!PPForm.profilationValue"
                        [disabled]="!PPForm.endScroll || !PPForm.isAccepted" />
                      <label class="custom-control-label" for="radio-2a" id="profilation-yes">{{ yesItem }}</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline" (click)="acceptProfilation(false)">
                      <input type="radio" id="radio-2b" name="radio-2" class="custom-control-input"
                        [value]="PPForm.profilationValue === false" [checked]="PPForm.profilationValue === false"
                        [disabled]="!PPForm.endScroll || !PPForm.isAccepted" />
                      <label class="custom-control-label" for="radio-2b" id="profilation-no">{{ noItem }}</label>
                    </div>
                  </div>

                </div>


                <!-- Slide DataProcessAgreement -->
                <div class="swiper-slide" *ngIf="DPAForm">

                  <p>{{ labels.LEGAL_TERMS_COMPONENT_EFFECTIVE_DATE }}: {{ DPAForm.effectiveDate | date :'shortDate' }}
                  </p>

                  <div class="swiper-slide-content" [innerHTML]="DPAForm.text" (scroll)="onDPAScroll($event)"></div>

                  <div class="form-group switch-custom-container" *ngIf="DPAForm.ack">
                    <label class="switch">
                      <input #dpa type="checkbox" [disabled]="!DPAForm.endScroll" [checked]="DPAForm.isAccepted"
                        (mousedown)="updateStartPosition($event)" (mouseup)="updateEndPosition($event)"
                        (click)="acceptDataProcessAgreement(dpa.checked)" />
                      <span></span>
                    </label>
                    <p>{{ DPAForm.ack }}</p>
                  </div>

                </div>

              </div>
            </div>

          </div>

          <div class="col-8">

            <p>{{ labels.LEGAL_TERMS_COMPONENT_LEGAL_SENTENCE }}</p>

          </div>


          <div class="col-12 btn-container">

            <button id="next-send-button" type="button" class="btn btn-primary" [disabled]="isNextDisabled"
              (click)="nextOrSend()">
              <span class="pr-3">{{ mySwiper?.activeIndex + 1 }}/{{ mySwiper?.slides.length }}</span>
              <span>{{ nextOrSendButton }}</span>
            </button>

          </div>

        </div>

      </form>

    </div>
