export const environment = {
	name: 'verification',
	production: true,
	domainUserAPI: "https://api-preprod21.orthonext.app/usr/v1",
	cdnUrl: 'https://cdnpreprod21.orthonext.app/ver',
	insightKey: '48977294-3785-404d-8481-a3e779ab4a2b',
	azureMapsKey: 'sgdF-w6qC3HfMY1yhhxmRV3RnAgYnrnENdgMylARww8',
	buildVer: 'OrthoNext-UserRequest FE VER21 CI-CD_24130.01',
	orthofixWebsite: 'https://www.orthofix.com',
	orthofixMedicalEducation: 'https://www.orthofixacademy.com',
	customerCareEmail: 'orthonext@orthofix.com',
	recaptchaSiteKey: '6LcXs80pAAAAACQmkVvn0AlruZGPkGCX3_4MIz9y',
	b2cTenantName: 'orthonextb2cpreprod21',
  b2cTenantId: '3e8f01db-2f26-4513-ac95-86181ab138e1',
  b2cSignInPolicyId: 'B2C_1_OnlySignIN',
  b2cResetPasswordPolicyId: 'B2C_1_PasswordReset',
  b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
  b2cClientId: 'fc4b5701-6d33-4eaa-9e12-f4d27e478a5c',
  b2cApplicationScope: 'openid profile https://orthonextb2cpreprod21.onmicrosoft.com/api/user_impersonation',
  appSSOutUriApi: 'https://api-preprod21.orthonext.app/services/appssouturi',
  idleTimeToEndSession: 1800,
	patplanSite: 'https://patpreprod21.orthonext.app',
	cookieName: '_iub_cs-60532175'
};
