<!-- Main container -->
<div class="container-fluid">

	<!-- First row wrap Breadcrumb + Title + Alert -->
	<div class="row row-header">
		<div class="col">

			<!-- Breadcrumbs -->
			<div class="breadcrumbs"></div>

			<!-- Logo full -->
			<div class="logo-full-text">{{labels.APP_LOGO_TEXT}}</div>

			<!-- Page title -->
			<h1>{{ labels.LEGAL_TERMS_PAGE_COMPONENT_TITLE }}</h1>

		</div>
	</div>


	<div class="row row-contents">

		<div class="col-2">
			<div class="form-group">
				<label for="country">{{ labels.LEGAL_TERMS_PAGE_COMPONENT_COUNTRY }}</label>
				<select class="form-control custom-select" id="country" autocomplete="off" [(ngModel)]="countryCode"
					(change)="updateDocuments()">
					<option *ngFor="let country of countryList;" [ngValue]="country.code">
						{{ country.name }}
					</option>
				</select>
			</div>
		</div>

		<ng-container *ngIf="docList?.length === 3">

			<div class="col-11">
				<div class="legal-swiper">
					<h3>{{ labels.LEGAL_TERMS_PAGE_COMPONENT_EULA }}</h3>
					<div class="swiper-slide-content" [innerHTML]="eulaText"></div>
				</div>
			</div>

			<div class="col-11">
				<div class="legal-swiper">
					<h3>{{ labels.LEGAL_TERMS_PAGE_COMPONENT_PRIVACY_POLICY }}</h3>
					<div class="swiper-slide-content" [innerHTML]="privacyPolicyText"></div>
				</div>
			</div>

			<div class="col-11">
				<div class="legal-swiper">
					<h3>{{ labels.LEGAL_TERMS_PAGE_COMPONENT_DPA }}</h3>
					<div class="swiper-slide-content" [innerHTML]="dpaText"></div>
				</div>
			</div>

		</ng-container>

		<ng-container *ngIf="docList && docList.length === 0">

			<div class="col-11" style="color: red">
				<p>{{ labels.LEGAL_TERMS_PAGE_COMPONENT_NO_DOCUMENTS }}</p>
			</div>

		</ng-container>

	</div>

</div>