import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../../core';
import { UserRequestService } from '../../services';
import { BaseComponent } from '../base-component';


/**
* User request success page component
*/
@Component({
  selector: 'success-page',
  templateUrl: './success-page.component.html',
  styles: []
})
export class SuccessPageComponent extends BaseComponent implements OnInit {

  readonly orthofixWebsite = environment.orthofixWebsite;
  readonly orthofixMedicalEducation = environment.orthofixMedicalEducation;
  readonly customerCareEmail = environment.customerCareEmail;

  constructor(
    private langSrv: LanguageService,
    private router: Router,
    private userSrv: UserRequestService
  ) {
    super(langSrv);
  }

  ngOnInit() {
    this.userSrv.successState.pipe(first()).subscribe(state => !state ? this.router.navigateByUrl('/userRequest') : null)
  }

}
