import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';


/**
 * Component that indicates the loading state of another component or page.
 */
@Component({
	selector: 'loader',
	templateUrl: './loader.component.html'
})
export class LoaderComponent {

	readonly LOADER_URL: string = environment.cdnUrl + '/style/images/loading.gif';

}