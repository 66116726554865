import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Country, LanguageService, LegalDocument, LegalDocumentTypeEnum, SURGEON_CODE } from '../core';
import { UserRequestService } from '../services';
import { BaseComponent } from '../shared';


/**
* Page for legal terms reading
*/
@Component({
	selector: 'app-legal-terms-page',
	templateUrl: './legal-terms-page.component.html'
})
export class LegalTermsPageComponent extends BaseComponent implements OnInit {

	countryCode: string;

	countryList: Country[];
	docList: LegalDocument[];
	eulaText: string;
	privacyPolicyText: string;
	dpaText: string;

	private readonly DEFAULT_COUNTRY_CODE: string = 'GB';

	constructor(
		private langSrv: LanguageService,
		private route: ActivatedRoute,
		private userSrv: UserRequestService,
		private titleSrv: Title
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.titleSrv.setTitle(this.labels.APP_LEGAL_TERMS_TITLE);
		this.userSrv.getCountries().subscribe(list => {
			this.countryList = list;
			this.initCountryCode();
			this.updateDocuments();
		});
	}

	private initCountryCode(): void {
		const code: string = this.route.snapshot.queryParamMap.get('countryCode');
		const country: Country = this.countryList.find(i => i.code === code);
		this.countryCode = country ? country.code : this.DEFAULT_COUNTRY_CODE;
	}

	/**
	* Update document list when change country selection
	*/
	updateDocuments(): void {
		this.userSrv.getLegalDocuments(this.countryCode, SURGEON_CODE).subscribe(docs => {
			this.docList = docs;
			this.eulaText = this.docList.find(doc => doc.type === LegalDocumentTypeEnum.EULA)?.text;
			this.privacyPolicyText = this.docList.find(doc => doc.type === LegalDocumentTypeEnum.PrivacyPolicy)?.text;
			this.dpaText = this.docList.find(doc => doc.type === LegalDocumentTypeEnum.DPA)?.text;
		});
	}
}
