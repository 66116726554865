/**
* Convert date in fr-CA string format, i.e yyyy-mm-dd
*/
export function getInputDate(value: Date): string {
  const date = new Date(value);
  return new Intl.DateTimeFormat('fr-CA').format(date);
}

/**
* Convert date in string format according locale language
*/
export function convertToLocalDate(value: Date): string {
  if (!!value) {
    const date = new Date(value);
    const lang: string = navigator.language;
    return new Intl.DateTimeFormat(lang).format(date);
  }
  return null;
}

/**
* Convert date in ISo format
*/
export function convertToISOString(date: Date): string {
  const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
  const isoString = utcDate.toISOString();
  return isoString;
}




const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

/**
* Convert ISO string in date
*/
export function parseIsoDateStrToDate(key: string, value: any) {
  if (typeof value === "string" && isoDateFormat.test(value)) {
    return new Date(value);
  }
  return value
}

/**
* Convert json date in object date
*/
export function revertJsonDate(date: any): any {
  const revertDate = JSON.parse(JSON.stringify(date), parseIsoDateStrToDate);
  return revertDate;
}
