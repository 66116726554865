import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageBindingStrategy, ReactiveFormConfig, RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import { Models } from 'azure-maps-rest';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Country, LanguageService, PracticeType, RoleInformationType, RoleTypeEnum, SURGEON_CODE, USA_CODE, USState, convertToLocalDate } from '../core';
import { UserRequestForm } from '../models';
import { MapsService, UserRequestService } from '../services';
import { BaseComponent } from '../shared';


/**
* User request form component
*/
@Component({
	selector: 'app-user-request',
	templateUrl: './user-request.component.html',
	styleUrls: ['./user-request.component.css']
})
export class UserRequestComponent extends BaseComponent implements OnInit, OnDestroy {

	buildVer: string = environment.buildVer;
	envName: string = environment.name;

	reqForm: RxFormGroup = <RxFormGroup>this.formBuilder.formGroup(UserRequestForm);
	isFormDetailsVisible: boolean;

	isOtherRoleVisible: boolean;
	isPracticeTypeVisible: boolean;
	isNpiVisible: boolean;

	isStateLicense1Visible: boolean;
	isStateLicense2Visible: boolean;
	isStateLicense3Visible: boolean;
	isStateLicense4Visible: boolean;
	isStateLicense5Visible: boolean;

	isDistributorVisible: boolean;
	isHospitalVisible: boolean;
	isCompanyVisible: boolean;
	isMapVisible: boolean;

	isUSStateVisible: boolean;

	USA_CODE: string = USA_CODE;
	HCP: string = RoleTypeEnum.HCP;
	OTHERS: string = RoleTypeEnum.OTHERS;

	allCountries: Country[] = [];
	allUSStates: Country[] = [];
	allOtherRoles: RoleInformationType[] = [];
	allPracticeTypes: PracticeType[] = [];

	hospitalPosition: Models.CoordinateAbbreviated;

	maxDateOfBirth: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

	private confirmEmailMismatchMsg: string;
	private emailExistSubscription: Subscription;
	private emailExistMsg: string;

	constructor(
		private service: UserRequestService,
		private formBuilder: RxFormBuilder,
		private langSrv: LanguageService,
		public mapsSrv: MapsService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.service.initDictionaries().subscribe(res => {
			this.allCountries = this.service.getAllCountries();
			this.allUSStates = this.service.getAllUSStates();
			this.allPracticeTypes = this.service.getAllPracticeTypes();
			this.allOtherRoles = this.service.getAllRoleTypes().filter(x => x.value != SURGEON_CODE);
		});
		this.initForm();
		this.emailExistSubscription = this.service.emailExistError.pipe(filter(err => !!err)).subscribe(err => this.setEmailExistError())
	}

	/**
	* Init form
	*/
	initForm() {
		const validationMsg = this.langSrv.getValidationMessages();
		this.confirmEmailMismatchMsg = validationMsg['VALIDATION_MESSAGE_COMPARE_CONFIRM_EMAIL'];
		this.emailExistMsg = validationMsg['VALIDATION_MESSAGE_ALREADY_EXIST_EMAIL'];
		ReactiveFormConfig.set({
			"validationMessage": this.langSrv.getValidationMessages(),
			"errorMessageBindingStrategy": ErrorMessageBindingStrategy.OnDirtyOrTouched,
			"baseConfig": {
				"dateFormat": "ymd",
				"seperator": "-"
			}
		});
		const userRequestForm = this.service.getUserRequestForm();
		this.reqForm = <RxFormGroup>this.formBuilder.formGroup(UserRequestForm, userRequestForm);
		this.isFormDetailsVisible = !!userRequestForm ? true : false;
		this.initHospitalOrCompany();
		this.initAddress();
		this.initPractice();
	}

	/**
	* Handle hospital/company fields visibility on init
	*/
	initHospitalOrCompany(): void {
		if (this.reqForm.value.roleType === RoleTypeEnum.OTHERS) {
			this.isOtherRoleVisible = true;
			this.isCompanyVisible = true;
			this.isHospitalVisible = false;
			this.isDistributorVisible = false;
		} else {
			this.isOtherRoleVisible = false;
			this.isCompanyVisible = false;
			this.isHospitalVisible = true;
			this.isDistributorVisible = true;
		}
	}

	/**
	* Handle address fields on init
	*/
	initAddress(): void {
		this.isUSStateVisible = this.isUnitedStates() ? true : false;
		if (!!this.reqForm.value.hospital && !!this.reqForm.value.hospital.position) {
			this.isMapVisible = true;
			const position = this.reqForm.value.hospital.position;
			this.hospitalPosition = { ...position };
		} else if (!!this.reqForm.value.company && !!this.reqForm.value.company.position) {
			this.isMapVisible = true;
			const position = this.reqForm.value.company.position;
			this.hospitalPosition = { ...position };
		}
	}

	/**
	* Handle practice fields on init
	*/
	initPractice(): void {
		if (this.reqForm.value.practiceType) {
			this.isPracticeTypeVisible = true;
			this.isNpiVisible = true;
			this.isStateLicense1Visible = true;
			this.isStateLicense2Visible = !!this.reqForm.value.licenseNumber2 ? true : false;
			this.isStateLicense3Visible = !!this.reqForm.value.licenseNumber3 ? true : false;
			this.isStateLicense4Visible = !!this.reqForm.value.licenseNumber4 ? true : false;
			this.isStateLicense5Visible = !!this.reqForm.value.licenseNumber5 ? true : false;
		}
	}

	/**
	* Handle confirmation email mismatch validation
	*/
	compareConfirmEmail(): void {
		const emailNull = () => this.reqForm.value.confirmEmail && !this.reqForm.value.email;
		const mismatch = () => this.reqForm.value.confirmEmail && this.reqForm.value.email && this.reqForm.value.confirmEmail.toLowerCase() !== this.reqForm.value.email.toLowerCase();
		if (emailNull() || mismatch()) {
			this.reqForm.controls.confirmEmail.setErrors({ confirmEmailMismatch: { message: this.confirmEmailMismatchMsg } });
		} else {
			this.reqForm.controls.confirmEmail.setValue(this.reqForm.value.confirmEmail);
		}
	}

	/**
	* Update Date of Birth field on date selection from date picker
	*/
	updateDateOfBirth(date: Date): void {
		this.reqForm.controls.dateOfBirth.setValue(date);
		this.reqForm.controls.dateOfBirthFormat.setValue(convertToLocalDate(date));
		this.reqForm.controls.dateOfBirth.markAsTouched();
	}

	/**
	* Reset Date of Birth field
	*/
	clearDateOfBirth(): void {
		this.reqForm.controls.dateOfBirth.setValue(null);
		this.reqForm.controls.dateOfBirth.markAsTouched();
		this.reqForm.controls.dateOfBirthFormat.setValue(null);
	}

	/**
	* Check if the countries are the same
	*/
	compareCountry(c1: Country, c2: Country): boolean {
		return (c1 == null && c2 == null) || (c1 && c2 && c1.id === c2.id)
	}

	/**
	* Display name of selected hospital 
	*/
	hospitalFormatter = (result: string | Models.SearchPoiResult) => typeof (result) === 'string' ? result : result.poi.name;

	/**
	* Display name of selected company
	*/
	companyFormatter = (result: string | Models.SearchPoiResult) => typeof (result) === 'string' ? result : result.poi.name;

	/**
	* Update form on Role Type selection
	*/
	updateRoleType() {
		if (this.reqForm.value.roleType != null) {
			this.isFormDetailsVisible = true;
		}
		if (this.reqForm.value.roleType === RoleTypeEnum.OTHERS) {
			this.isOtherRoleVisible = true;
		} else {
			this.isOtherRoleVisible = false;
			this.reqForm.controls.otherRole.setValue(null);
			this.reqForm.controls.otherRole.markAsUntouched();
		}
		this.updateHospitalCompanyVisibility();
		this.refreshPractice();
	}

	/**
	* Update hospital/company fields
	*/
	updateHospitalCompanyVisibility() {
		if (this.reqForm.value.roleType === RoleTypeEnum.OTHERS) {
			this.isCompanyVisible = true;
			this.isDistributorVisible = false;
			this.isHospitalVisible = false;
			this.reqForm.controls.distributor.setValue(null);
			this.reqForm.controls.distributor.markAsUntouched();
			this.reqForm.controls.hospital.setValue(null);
			this.reqForm.controls.hospital.markAsUntouched();
			this.selectPOI(null);
		} else {
			this.isCompanyVisible = false;
			this.isDistributorVisible = true;
			this.isHospitalVisible = true;
			this.reqForm.controls.company.setValue(null);
			this.reqForm.controls.company.markAsUntouched();
			this.selectPOI(null);
		}
	}

	/**
	* Update address fields on country selection
	*/
	updateCountry() {
		if (this.isUnitedStates()) {
			this.isUSStateVisible = true;
			this.reqForm.controls.stateProvince.setValue(null);
			this.reqForm.controls.stateProvince.markAsUntouched();
			this.reqForm.controls.usStateProvince.setValue(this.reqForm.value.usStateProvince);
			this.reqForm.controls.usStateProvince.markAsTouched();
		} else {
			this.isUSStateVisible = false;
			this.reqForm.controls.usStateProvince.setValue(null);
			this.reqForm.controls.usStateProvince.markAsUntouched();
			this.reqForm.controls.stateProvince.setValue(this.reqForm.value.stateProvince);
			this.reqForm.value.country ? this.reqForm.controls.stateProvince.markAsTouched() : this.reqForm.controls.stateProvince.markAsUntouched();
		}
		this.refreshPractice();
	}

	/**
	* Reset practice type field 
	*/
	refreshPractice() {
		if (this.isUnitedStates() && this.reqForm.value.roleType === this.HCP) {
			this.isPracticeTypeVisible = true;
		} else {
			this.isPracticeTypeVisible = false;
			this.reqForm.controls.practiceType.setValue(null);
			this.reqForm.controls.practiceType.markAsUntouched();
		}
		this.updatePracticeType();
	}

	/**
	* Reset practice fields
	*/
	updatePracticeType() {
		if (this.reqForm.value.practiceType == null) {
			this.reqForm.controls.npi.setValue(null);
			this.reqForm.controls.npi.markAsUntouched();
			this.isNpiVisible = false;
			this.reqForm.controls.stateCode1.setValue(null);
			this.reqForm.controls.licenseNumber1.setValue(null);
			this.reqForm.controls.stateCode1.markAsUntouched();
			this.reqForm.controls.licenseNumber1.markAsUntouched();
			this.isStateLicense1Visible = false;
			this.removeStateLicense(2);
			this.removeStateLicense(3);
			this.removeStateLicense(4);
			this.removeStateLicense(5);
		} else {
			this.isNpiVisible = true;
			this.isStateLicense1Visible = true;
		}
	}

	/**
	* Remove state license fields
	*/
	removeStateLicense(num: number): void {
		this.reqForm.controls[`stateCode${num}`].setValue(null);
		this.reqForm.controls[`licenseNumber${num}`].setValue(null);
		this.reqForm.controls[`stateCode${num}`].markAsUntouched();
		this.reqForm.controls[`licenseNumber${num}`].markAsUntouched();
		this[`isStateLicense${num}Visible`] = false
	}

	/**
	* Update state code field
	*/
	updateStateCode(num: number): void {
		this.reqForm.controls[`licenseNumber${num}`].setValue(this.reqForm.value[`licenseNumber${num}`]);
		this.reqForm.controls[`licenseNumber${num}`].markAsTouched();
	}

	/**
	* Update license number fiels
	*/
	updateLicenseNumber(num: number): void {
		this.reqForm.controls[`stateCode${num}`].setValue(this.reqForm.value[`stateCode${num}`]);
		this.reqForm.controls[`stateCode${num}`].markAsTouched();
	}

	/**
	* Update address fields on hospital selection
	*/
	updateHospital() {
		if (!(this.reqForm.value.hospital instanceof Object)) {
			this.selectPOI(null);
		}
	}

	/**
	* Update address fields on company selection
	*/
	updateCompany() {
		if (!(this.reqForm.value.company instanceof Object)) {
			this.selectPOI(null);
		}
	}

	/**
	* Update address fields on autocomplete result selection
	*/
	selectPOI(ev: NgbTypeaheadSelectItemEvent) {
		if (ev != null) {
			const item: Models.SearchPoiResult = ev.item;
			this.reqForm.controls.address.setValue(item.address.streetName && item.address.streetNumber ? item.address.streetName + " " + item.address.streetNumber : null);
			this.reqForm.controls.city.setValue(item.address.municipality);
			this.reqForm.controls.postalCode.setValue(item.address.postalCode);
			this.reqForm.controls.country.setValue(this.getCountryByCode(item.address.countryCode));
			this.updateCountry();
			if (this.isUnitedStates()) {
				this.reqForm.controls.usStateProvince.setValue(this.getUSStateByCode(item.address.countrySubdivision));
			} else {
				this.reqForm.controls.stateProvince.setValue(item.address.countrySubdivisionName || item.address.countrySubdivision);
			}
			this.isMapVisible = true;
			this.hospitalPosition = {
				lat: item.position.lat,
				lon: item.position.lon
			};
			this.reqForm.controls.address.markAsTouched();
			this.reqForm.controls.city.markAsTouched();
			this.reqForm.controls.postalCode.markAsTouched();
			this.reqForm.controls.country.markAsTouched();
		} else if (this.hospitalPosition != null) {
			this.reqForm.controls.address.setValue(null);
			this.reqForm.controls.city.setValue(null);
			this.reqForm.controls.postalCode.setValue(null);
			this.reqForm.controls.country.setValue(null);
			this.reqForm.controls.stateProvince.setValue(null);
			this.reqForm.controls.usStateProvince.setValue(null);
			this.updateCountry();
			this.isMapVisible = false;
			this.hospitalPosition = null;
			this.reqForm.controls.address.markAsUntouched();
			this.reqForm.controls.city.markAsUntouched();
			this.reqForm.controls.postalCode.markAsUntouched();
			this.reqForm.controls.country.markAsUntouched();
		}
	}

	/**
	* Handle user request on form submitting
	*/
	onSubmit() {
		this.service.handleFormSubmit(this.reqForm.value);
	}

	private isUnitedStates() {
		return this.reqForm.value.country != null && this.reqForm.value.country.code == USA_CODE;
	}

	private getCountryByCode(code: string): Country {
		const result: Country[] = this.allCountries.filter(c => c.code == code);
		return result.length == 1 ? result[0] : null;
	}

	private getUSStateByCode(code: string): USState {
		const result: USState[] = this.allUSStates.filter(c => c.code == code);
		return result.length == 1 ? result[0] : null;
	}

	private setEmailExistError(): void {
		this.reqForm.controls.email.setErrors({ emailExist: { message: this.emailExistMsg } })
	}




	ngOnDestroy() {
		if (this.emailExistSubscription) {
			this.emailExistSubscription.unsubscribe();
		}
	}

}
