import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { throwError } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LanguageService, LoaderService } from '../core';
import { UserRequestService } from '../services';
import { BaseComponent, ConfirmationComponent } from '../shared';


/**
 * Wrapper component for legal terms acceptance.
 */
@Component({
	selector: 'app-legal-terms-acceptance',
	templateUrl: './legal-terms-acceptance.component.html'
})
export class LegalTermsAcceptanceComponent extends BaseComponent implements OnInit {

	loading: boolean = true;

	readonly loaderSrc = `${environment.cdnUrl}/style/images/loading.gif`;

	@HostListener('window:popstate', ['$event']) onPopState() {
		this.loaderSrv.show();
		window.location.href = environment.patplanSite;
	}

	constructor(
		private langSrv: LanguageService,
		private srv: UserRequestService,
		private modalSrv: NgbModal,
		private titleSrv: Title,
		private loaderSrv: LoaderService
	) {
		super(langSrv);
	}

	ngOnInit(): void {
		this.titleSrv.setTitle(this.labels.APP_LEGAL_TERMS_TITLE);
		let countryCode: string;
		this.srv.getUserProfile().pipe(
			tap(usr => countryCode = usr?.country?.code),
			switchMap(usr => usr?.hasToAcceptLegalDocs ? this.srv.getLegalDocuments(usr.country.code, usr.roleInfo) : throwError(() => new Error("UserNotValid"))),
			tap(docs => this.srv.setLegalDocumentToAccept(docs, countryCode)),
		).subscribe({
			next: () => this.loading = false,
			error: (err: Error) => {
				if (err?.message == "UserNotValid") this._openInvalidUserModal(); else throw err;
			}
		});
	}

	private _openInvalidUserModal(): void {
		const modalRef: NgbModalRef = this.modalSrv.open(ConfirmationComponent, {
			centered: true, backdrop: 'static'
		});
		(modalRef.componentInstance as ConfirmationComponent).config = {
			title: this.labels['LEGAL_TERMS_ACCEPTANCE_COMPONENT_MODAL_TITLE'],
			message: this.labels['LEGAL_TERMS_ACCEPTANCE_COMPONENT_MODAL_MSG'],
			confirmBtn: this.labels['LEGAL_TERMS_ACCEPTANCE_COMPONENT_MODAL_CONFIRM'],
			isCancelBtn: false
		};
		modalRef.result.then(() => this._returnToPatient());
	}

	private _returnToPatient(): void {
		window.location.href = environment.patplanSite;
	}

}
