import { Component } from '@angular/core';
import { LanguageService } from '../../core';


/**
* Base component to share common features in every component
*/
@Component({
  selector: 'base-component',
  templateUrl: './base.component.html'
})
export class BaseComponent {

  private _labels: any = {};

  constructor(private languageSrv: LanguageService) {
    this._labels = languageSrv.getLabels();
  }

  /**
  * Get labels
  */
  get labels() {
    return this._labels;
  }

}
