import { AuthCoreConfig } from '@ortho-next/auth-core';
import { environment } from '../environments/environment';


/**
* Authentication configuration for application
*/
export const AUTH_CONFIG: AuthCoreConfig = {
	allowedUrls: [
		environment.domainUserAPI + "/api/users/GetUserProfile",
		environment.domainUserAPI + "/api/request/SetDocumentsApproval"
	],
	appSSOutUriApi: environment.appSSOutUriApi,
	applicationScope: environment.b2cApplicationScope,
	clientId: environment.b2cClientId,
	idleTimeToEndSession: environment.idleTimeToEndSession,
	tenantName: environment.b2cTenantName,
	resetPasswordPolicyId: environment.b2cResetPasswordPolicyId,
	signInPolicyId: environment.b2cSignInPolicyId,
	changePasswordPolicyId: environment.b2cChangePasswordPolicyId,
	showDebugInformation: environment.name !== 'production'
};
