import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationType, Map, data, layer, source } from 'azure-maps-control';
import { Models } from 'azure-maps-rest';
import { environment } from '../../../environments/environment';


/**
* Map component to display selected hospital/company coordinates
*/
@Component({
	selector: 'app-map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

	private updateMapEvent = () => this.updateMap();

	@Input() set position(position: Models.CoordinateAbbreviated) {
		this._position = position;
		if (this.map != null && this._position != null) {
			this.map.events.add('ready', this.updateMapEvent);
		}
	}

	get position(): Models.CoordinateAbbreviated { return this._position; }

	map: Map;

	private _position: Models.CoordinateAbbreviated;
	private zoom: number = 10;
	private readonly sourceId: string = "SOURCE_ID";
	private readonly layerId: string = "LAYER_ID";
	private readonly orthofixPosition = {
		lat: 45.44,
		lon: 10.86
	};

	ngOnInit() {

		this._position = this._position || this.orthofixPosition;

		this.map = new Map('myMap', {
			center: new data.Position(this._position.lon, this._position.lat),
			zoom: this.zoom,
			autoResize: true,
			interactive: false, // disable user interaction
			enableAccessibility: false, // remove text under map
			showLogo: false,
			authOptions: {
				authType: AuthenticationType.subscriptionKey,
				subscriptionKey: environment.azureMapsKey
			}
		});

		this.map.events.add('ready', this.updateMapEvent);

	}

	/**
	* Update map according selected coordinates
	*/
	updateMap() {

		// clear previous source with related layer
		if (this.map.sources.getById(this.sourceId) != null) {
			this.map.layers.remove(this.layerId);
			this.map.sources.remove(this.sourceId);
		}

		// initialize new source
		let datasource = new source.DataSource(this.sourceId);
		this.map.sources.add(datasource);

		// add new layer
		const resultLayer = new layer.SymbolLayer(datasource, this.layerId, {
			iconOptions: {
				image: 'pin-darkblue',
				anchor: 'center',
			}
		});

		this.map.layers.add(resultLayer);

		// add new shape and set camera
		const userPosition = new data.Position(this._position.lon, this._position.lat);
		const userPoint = new data.Point(userPosition)
		const feature = new data.Feature(userPoint);
		datasource.add(feature);
		this.map.setCamera({
			center: userPosition,
			zoom: this.zoom,
		});

		this.map.resize();

		this.map.events.remove('ready', this.updateMapEvent);
	}

}
