import { Injectable } from '@angular/core';
import { Aborter, MapsURL, Pipeline, SearchURL, SubscriptionKeyCredential } from 'azure-maps-rest';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';


/**
* This services handles the hospital/company autocomplete search
*/
@Injectable()
export class MapsService {

  private readonly searchURL: SearchURL;

  constructor() {

    //Use SubscriptionKeyCredential with a subscription key.
    const subscriptionKeyCredential: SubscriptionKeyCredential = new SubscriptionKeyCredential(environment.azureMapsKey);

    //Use subscriptionKeyCredential to create a pipeline.
    const pipeline: Pipeline = MapsURL.newPipeline(subscriptionKeyCredential, { retryOptions: { maxTries: 4 } });

    //Create an instance of the SearchURL client.
    this.searchURL = new SearchURL(pipeline);

  }

  /**
  * Search for the hospitals on text changes
  */
  searchHospitals = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(x => x.length > 2),
      switchMap(x => this.searchURL.searchPOI(Aborter.timeout(10000), x, { typeahead: true, limit: 100 })),
      //tap(x => console.log(x)),
      map(x => x.results),
      map(x => x.filter(o => o.poi.classifications[0].code == "HOSPITAL_POLYCLINIC")),
      map(x => x.filter(o => x.indexOf(o) <= 5)),
      tap(x => console.log(x))
    );

  /**
  * Search for the companies on text changes
  */
  searchCompanies = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(x => x.length > 2),
      switchMap(x => this.searchURL.searchPOI(Aborter.timeout(10000), x, { typeahead: true, limit: 100 })),
      //tap(x => console.log(x)),
      map(x => x.results),
      map(x => x.filter(o => o.poi.classifications[0].code == "COMPANY")),
      map(x => x.filter(o => x.indexOf(o) <= 5)),
      tap(x => console.log(x))
    );

}
