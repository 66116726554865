import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from '../core';
import { BaseComponent } from './base-component';
import { ConfirmationComponent } from './confirmation';
import { DatepickerComponent } from './datepicker';
import { MapComponent } from './map';
import { SuccessPageComponent } from './success-page';

@NgModule({
	declarations: [
		BaseComponent,
		MapComponent,
		SuccessPageComponent,
		ToastComponent,
		DatepickerComponent,
		ConfirmationComponent
	],
	exports: [
		BaseComponent,
		MapComponent,
		SuccessPageComponent,
		ToastComponent,
		DatepickerComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		NgbToastModule,
		NgbDatepickerModule
	],
	entryComponents: [
		ConfirmationComponent
	],
})
export class SharedModule { }
