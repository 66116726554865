import { compose, email, maxDate, maxLength, minDate, pattern, prop, required, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Models } from 'azure-maps-rest';
import { Country, RoleTypeEnum, USA_CODE, USState } from '../core';

const specialChars: RegExp = /^[^<>%#&?,]+$/;
const datePattern: RegExp = /^[1-9]\d{3}-\d\d-\d\d$/;


/**
* User request form model
*/
export class UserRequestForm {
	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_EMAIL" })
	@maxLength({ value: 128, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_EMAIL" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_EMAIL" })
	@email({ messageKey: "VALIDATION_MESSAGE_FORMAT_EMAIL" })
	email: string;

	@required({ messageKey: "VALIDALIDATION_MESSAGE_REQUIRED_CONFIRM_EMAIL" })
	@maxLength({ value: 128, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_CONFIRM_EMAIL" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_CONFIRM_EMAIL" })
	@email({ messageKey: "VALIDATION_MESSAGE_FORMAT_CONFIRM_EMAIL" })
	//@compare({ fieldName: "email", messageKey: "VALIDATION_MESSAGE_COMPARE_CONFIRM_EMAIL" })
	confirmEmail: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_FIRST_NAME" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_FIRST_NAME" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_FIRST_NAME" })
	firstName: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LAST_NAME" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LAST_NAME" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LAST_NAME" })
	lastName: string;

	//@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_DATE_OF_BIRTH", conditionalExpression: (x: UserRequestForm) => x.dateOfBirth === null })
	//@required({ messageKey: "VALIDATION_MESSAGE_INVALID_DATE_OF_BIRTH", conditionalExpression: (x: UserRequestForm) => x.dateOfBirth === '' })
	//@pattern({ expression: { validDate: datePattern }, messageKey: "VALIDATION_MESSAGE_INVALID_DATE_OF_BIRTH" })
	//@maxDate({ value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)), messageKey: "VALIDATION_MESSAGE_MAXDATE_DATE_OF_BIRTH" })
	//@minDate({ value: new Date(1899, 12, 1), operator: '>=', messageKey: "VALIDATION_MESSAGE_INVALID_DATE_OF_BIRTH" })
	//dateOfBirth: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_DATE_OF_BIRTH" })
	@maxDate({ value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)), operator: '<=', messageKey: "VALIDATION_MESSAGE_MAXDATE_DATE_OF_BIRTH" })
	@minDate({ value: new Date(1899, 12, 1), operator: '>=', messageKey: "VALIDATION_MESSAGE_INVALID_DATE_OF_BIRTH" })
	dateOfBirth: Date;

	@prop()
	dateOfBirthFormat: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COUNTRY" })
	country: Country;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_ROLE_TYPE" })
	roleType: RoleTypeEnum;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_ROLE", conditionalExpression: (x: UserRequestForm) => x.roleType == RoleTypeEnum.OTHERS })
	otherRole: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PRACTICE_TYPE", conditionalExpression: (x: UserRequestForm) => x.country != null && x.country.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	practiceType: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_NPI", conditionalExpression: (x: UserRequestForm) => x.country != null && x.country.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	@compose({ validators: [RxwebValidators.digit(), RxwebValidators.minLength({ value: 10 }), RxwebValidators.maxLength({ value: 10 })], messageKey: "VALIDATION_MESSAGE_DIGIT_NPI" })
	npi: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserRequestForm) => x.country != null && x.country.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	stateCode1: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserRequestForm) => x.country != null && x.country.code == USA_CODE && x.roleType == RoleTypeEnum.HCP })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	licenseNumber1: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserRequestForm) => x.licenseNumber2 })
	stateCode2: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserRequestForm) => x.stateCode2 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	licenseNumber2: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserRequestForm) => x.licenseNumber3 })
	stateCode3: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserRequestForm) => x.stateCode3 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	licenseNumber3: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserRequestForm) => x.licenseNumber4 })
	stateCode4: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserRequestForm) => x.stateCode4 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	licenseNumber4: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_STATE", conditionalExpression: (x: UserRequestForm) => x.licenseNumber5 })
	stateCode5: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_LICENSE_NUMBER", conditionalExpression: (x: UserRequestForm) => x.stateCode5 })
	@maxLength({ value: 17, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_LICENSE_NUMBER" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_LICENSE_NUMBER" })
	licenseNumber5: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_DISTRIBUTOR", conditionalExpression: (x: UserRequestForm) => x.roleType == RoleTypeEnum.HCP })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_DISTRIBUTOR" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_DISTRIBUTOR" })
	distributor: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_HOSPITAL", conditionalExpression: (x: UserRequestForm) => x.roleType == RoleTypeEnum.HCP })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_HOSPITAL", conditionalExpression: (x: UserRequestForm) => typeof (x.hospital) == "string" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_HOSPITAL" })
	hospital: string | Models.SearchPoiResult;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_COMPANY", conditionalExpression: (x: UserRequestForm) => x.roleType == RoleTypeEnum.OTHERS })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_COMPANY", conditionalExpression: (x: UserRequestForm) => typeof (x.company) == "string" })
	company: string | Models.SearchPoiResult;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_ADDRESS" })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_ADDRESS" })
	address: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_CITY" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_CITY" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_CITY" })
	city: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PROVINCE", conditionalExpression: (x: UserRequestForm) => x.country == null || x.country != null && x.country.code != USA_CODE })
	@maxLength({ value: 200, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PROVINCE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PROVINCE" })
	stateProvince: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PROVINCE", conditionalExpression: (x: UserRequestForm) => x.country == null || x.country != null && x.country.code == USA_CODE })
	usStateProvince: USState;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_POSTAL_CODE" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_POSTAL_CODE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_POSTAL_CODE" })
	postalCode: string;

	@required({ messageKey: "VALIDATION_MESSAGE_REQUIRED_PHONE" })
	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_PHONE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_PHONE" })
	officePhone: string;

	@maxLength({ value: 50, messageKey: "VALIDATION_MESSAGE_MAXLENGTH_MOBILE" })
	@pattern({ expression: { 'noSpecialChars': specialChars }, messageKey: "VALIDATION_MESSAGE_SPECIALCHARS_MOBILE" })
	mobilePhone: string;

}
