import { DocumentItemTypeEnum } from './lookup';


/**
* Document item model
*/
export class DocumentItem {
	id?: number;
	displayItem: string;
	itemType: DocumentItemTypeEnum;
}

export const NO_ITEMS: Map<string, string> = new Map([
	["No_ar", "لا"],
	["No_cn", "不是"],
	["No_cz", "ne"],
	["No_de", "nein"],
	["No_dk", "Nei"],
	["No_en", "no"],
	["No_es", "no"],
	["No_fr", "non"],
	["No_gr", "Όχι"],
	["No_it", "no"],
	["No_jp", "いいえ"],
	["No_nl", "Nee"],
	["No_pr", "Não"],
	["No_sl", "ne"],
	["No_sw", "nej"]
]);

export const YES_ITEMS: Map<string, string> = new Map([
	["Yes_ar", "نعم"],
	["Yes_cn", "是的"],
	["Yes_cz", "ano"],
	["Yes_de", "ja"],
	["Yes_dk", "ja"],
	["Yes_en", "yes"],
	["Yes_es", "sì"],
	["Yes_fr", "oui"],
	["Yes_gr", "ναί"],
	["Yes_it", "sì"],
	["Yes_jp", "はい"],
	["Yes_nl", "ja"],
	["Yes_pr", "sim"],
	["Yes_sl", "ja"],
	["Yes_sw", "ja"]
]);
