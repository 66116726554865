import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swiper from 'swiper';
import { DocumentItemTypeEnum, LanguageService, LegalDocument, LegalDocumentAcceptance, LegalDocumentTypeEnum, NoMandatoryConsensus } from '../core';
import { UserRequestService } from '../services';
import { BaseComponent } from '../shared';


/**
* Page for  legal documents acceptance
*/
@Component({
	selector: 'app-legal-terms',
	templateUrl: './legal-terms.component.html'
})
export class LegalTermsComponent extends BaseComponent implements OnInit, AfterViewInit {

	@Input() isAcceptance: boolean = false;
	@Input() sendLbl: string = this.labels.LEGAL_TERMS_COMPONENT_BUTTON_SEND;

	mySwiper: any;
	isNextDisabled: boolean = true;
	nextOrSendButton: string;
	yesItem: string;
	noItem: string;

	legalDocuments: LegalDocument[];

	EULAForm = {
		text: null,
		effectiveDate: null,
		endScroll: null,
		eula1: null,
		add_msg: null,
		isAccepted: null
	};

	PPForm = {
		text: null,
		effectiveDate: null,
		endScroll: null,
		ack: null,
		isAccepted: null,
		marketingId: null,
		marketingTxt: null,
		marketingValue: null,
		profilationId: null,
		profilationTxt: null,
		profilationValue: null
	};

	DPAForm = {
		text: null,
		effectiveDate: null,
		endScroll: null,
		ack: null,
		isAccepted: null
	};


	constructor(
		private service: UserRequestService,
		private router: Router,
		private langSrv: LanguageService
	) {
		super(langSrv);
	}

	ngOnInit() {
		this.legalDocuments = this.service.getLegalDocumentToAccept();
		if (this.legalDocuments?.length) {
			this.initEULAForm();
			this.initPPForm();
			this.initDPAForm();
		} else {
			console.error('No legal documents to accept');
			this.router.navigateByUrl('/error');
		}
		this.yesItem = this.service.getYesItem();
		this.noItem = this.service.getNoItem();
		this.nextOrSendButton = 'Next';
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.mySwiper = new Swiper('.swiper-container', {
				allowTouchMove: false,
				autoHeight: true,
				spaceBetween: 50,
				navigation: {
					nextEl: '#next-send-button',
				},
				pagination: {
					el: '.swiper-pagination',
					type: 'fraction',
				},
			});
		});
	}

	/**
	* Init form for UELA document
	*/
	initEULAForm() {
		const eula = this.legalDocuments.find(doc => doc.type === LegalDocumentTypeEnum.EULA);
		if (eula) {
			this.EULAForm.text = eula.text;
			this.EULAForm.effectiveDate = eula.effectiveDate;
			this.EULAForm.eula1 = eula.items.find(item => item.itemType === DocumentItemTypeEnum.EULA_I_Accept)?.displayItem ?? null;
			this.EULAForm.isAccepted = false;
			this.EULAForm.add_msg = eula.items.find(item => item.itemType === DocumentItemTypeEnum.EULA_Additional_Msg)?.displayItem ?? null;
		} else {
			this.EULAForm = null;
		}
	}

	/**
	* Init form for Privacy Policy document
	*/
	initPPForm() {
		const pp = this.legalDocuments.find(doc => doc.type === LegalDocumentTypeEnum.PrivacyPolicy);
		if (pp) {
			this.PPForm.text = pp.text;
			this.PPForm.effectiveDate = pp.effectiveDate;
			this.PPForm.ack = pp.items.find(item => item.itemType === DocumentItemTypeEnum.PP_Acknowledgement)?.displayItem ?? null;
			this.PPForm.isAccepted = false;
			const marketing = pp.items.find(item => item.itemType === DocumentItemTypeEnum.PP_DirectMarketing);
			this.PPForm.marketingId = marketing?.id ?? null;
			this.PPForm.marketingTxt = marketing?.displayItem ?? null;
			this.PPForm.marketingValue = null;
			const profilation = pp.items.find(item => item.itemType === DocumentItemTypeEnum.PP_UserProfilation);
			this.PPForm.profilationId = profilation?.id ?? null;
			this.PPForm.profilationTxt = profilation?.displayItem ?? null;
			this.PPForm.profilationValue = null;
		} else {
			this.PPForm = null;
		}
	}

	/**
	* Init form for Data Process Agreement document
	*/
	initDPAForm() {
		const dpa = this.legalDocuments.find(doc => doc.type === LegalDocumentTypeEnum.DPA);
		if (dpa) {
			this.DPAForm.text = dpa.text;
			this.DPAForm.effectiveDate = dpa.effectiveDate;
			this.DPAForm.ack = dpa.items.find(item => item.itemType === DocumentItemTypeEnum.DPA_Acknowledgement)?.displayItem ?? null;
			this.DPAForm.isAccepted = false;
		} else {
			this.DPAForm = null;
		}
	}

	// TOGGLE: IF DURING CLICK CHANGE MOUSE POSITION, TOGGLE NOT CHANGE TRANSITION
	posX: number = 0;
	posY: number = 0;
	endX: number = 0;
	endY: number = 0;

	/**
	* Update start position of toggle click
	*/
	updateStartPosition(ev: MouseEvent): void {
		this.posX = ev.clientX;
		this.posY = ev.clientY;
	}

	/**
	* Update end position of toggle click
	*/
	updateEndPosition(ev: MouseEvent): void {
		this.endX = ev.clientX;
		this.endY = ev.clientY;
	}

	private get isPositionEqual(): boolean {
		return this.endX === this.posX && this.endY === this.posY
	}

	private resetPositions(): void {
		this.posX = 0;
		this.posY = 0;
		this.endX = 0;
		this.endY = 0;
	}
	// FINE TOGGLE

	/**
	* Handle EULA document acceptance
	*/
	acceptEULA(value: boolean): void {
		if (this.isPositionEqual) {
			this.EULAForm.isAccepted = value;
			this.verifyEULA();
		}
		this.resetPositions();
	}

	/**
	* Verify if EULA document is accepted
	*/
	verifyEULA() {
		this.isNextDisabled = !(this.EULAForm.isAccepted && this.EULAForm.endScroll);
	}

	/**
	* Handle Privacy Policy document acceptance
	*/
	acceptPrivacyPolicy(value: boolean) {
		if (this.isPositionEqual) {
			this.PPForm.isAccepted = value;
			if (!this.PPForm.isAccepted) {
				this.PPForm.marketingValue = null;
				this.PPForm.profilationValue = null;
			}
			this.verifyPrivacyPolicy();
		}
		this.resetPositions();
	}

	/**
	* Handle Marketing acceptance
	*/
	acceptMarketing(value: boolean) {
		if (this.PPForm.isAccepted) {
			this.PPForm.marketingValue = value;
			this.verifyPrivacyPolicy();
		}
	}

	/**
	* Handle Profilation acceptance
	*/
	acceptProfilation(value: boolean) {
		if (this.PPForm.isAccepted) {
			this.PPForm.profilationValue = value;
			this.verifyPrivacyPolicy();
		}
	}

	/**
	* Verify if Provacy Policy document is accepted
	*/
	verifyPrivacyPolicy() {
		if (this.PPForm.isAccepted && this.PPForm.endScroll) {
			if (this.PPForm.marketingTxt && this.PPForm.profilationTxt) {
				this.isNextDisabled = this.PPForm.marketingValue == null || this.PPForm.profilationValue == null;
			} else if (this.PPForm.marketingTxt) {
				this.isNextDisabled = this.PPForm.marketingValue == null;
			} else if (this.PPForm.profilationTxt) {
				this.isNextDisabled = this.PPForm.profilationValue == null;
			} else {
				this.isNextDisabled = !this.PPForm.isAccepted;
			}
		} else {
			this.isNextDisabled = true;
		}
	}

	/**
	* Handle Data Process Agreement acceptance
	*/
	acceptDataProcessAgreement(value: boolean) {
		if (this.isPositionEqual) {
			this.DPAForm.isAccepted = value;
			this.verifyDPA();
		}
		this.resetPositions();
	}

	/**
	* Verify if Data Process Agreement document is accepted
	*/
	verifyDPA() {
		this.isNextDisabled = !(this.DPAForm.isAccepted && this.DPAForm.endScroll);
	}

	/**
	* Handle send request button
	*/
	nextOrSend() {
		if ((!this.PPForm.isAccepted && this.DPAForm === null) || (this.PPForm.isAccepted && this.DPAForm != null)) {
			(this.mySwiper as Swiper).slideNext();
			this.nextOrSendButton = this.sendLbl;
		}
		if (this.EULAForm.isAccepted && this.PPForm.isAccepted && (this.DPAForm == null || this.DPAForm.isAccepted)) {
			this.confirm();
		} else {
			(this.mySwiper as Swiper).slideNext();
			this.isNextDisabled = true;
		}
	}

	private getLegalDocumentAcceptance(): LegalDocumentAcceptance[] {
		const legalDocAcceptance: LegalDocumentAcceptance[] = [];
		this.legalDocuments.forEach(doc => legalDocAcceptance.push({ id: doc.id, type: doc.type, isAccepted: true }));
		let otherConsensus: NoMandatoryConsensus[] = [];
		if (this.PPForm.marketingId) {
			otherConsensus.push({ documentItemID: this.PPForm.marketingId, isAccepted: this.PPForm.marketingValue });
		}
		if (this.PPForm.profilationId) {
			otherConsensus.push({ documentItemID: this.PPForm.profilationId, isAccepted: this.PPForm.profilationValue });
		}
		const privacyPolicy = legalDocAcceptance.find(doc => doc.type === LegalDocumentTypeEnum.PrivacyPolicy);
		privacyPolicy.otherConsensus = otherConsensus.length > 0 ? otherConsensus : null;
		return legalDocAcceptance;
	}

	/**
	* Confirm legal documents
	*/
	private confirm(): void {
		const legalDocAcceptance = this.getLegalDocumentAcceptance();
		this.service.handleLegalDocAcceptance(legalDocAcceptance, this.isAcceptance);
	}

	/**
	* Handle EULA box scroll event
	*/
	onEULAScroll(event: any) {
		if (!this.EULAForm.endScroll) {
			if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
				this.EULAForm.endScroll = true;
				this.verifyEULA();
			}
		}
	}

	/**
	* Handle Privacy Policy box scroll event
	*/
	onPPScroll(event: any) {
		if (!this.PPForm.endScroll) {
			if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
				this.PPForm.endScroll = true;
				this.verifyPrivacyPolicy();
			}
		}
	}

	/**
	* Handle Data Process Agreement box scroll event
	*/
	onDPAScroll(event: any) {
		if (!this.DPAForm.endScroll) {
			if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
				this.DPAForm.endScroll = true;
				this.verifyDPA();
			}
		}
	}

}
