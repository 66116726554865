import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUserRepository } from './Iuser.repository';
import { ApiResponse, Dictionary, LegalDocument, LegalDocumentAcceptance, UserProfile, UserRequest } from './models';

@Injectable()
export class UserRepository implements IUserRepository {

	constructor(private httpClient: HttpClient) { }

	getDictionaries(nameList: string): Observable<ApiResponse<Dictionary[]>> {
		let params = new HttpParams().set('nameList', nameList);
		return this.httpClient.get<ApiResponse<Dictionary[]>>(environment.domainUserAPI + "/api/request/getdictionaries", { params: params });
	}

	getLegalDocuments(countryCode: string, roleType: string): Observable<ApiResponse<LegalDocument[]>> {
		let params = new HttpParams().set('countryCode', countryCode).set('roleType', roleType);
		return this.httpClient.get<ApiResponse<LegalDocument[]>>(environment.domainUserAPI + "/api/request/getlegaldocs", { params: params });
	}

	sendRequest(request: UserRequest, captchaToken: string): Observable<ApiResponse<any>> {
		const headers = new HttpHeaders({ 'X-Recaptcha-Token': captchaToken });
		return this.httpClient.post<ApiResponse<any>>(environment.domainUserAPI + "/api/request/newuserrequest", request, { headers });
	}

	getUserProfile(): Observable<ApiResponse<UserProfile>> {
		return this.httpClient.get<ApiResponse<UserProfile>>(environment.domainUserAPI + "/api/users/GetUserProfile");
	}

	setDocumentsApproval(legalDocumentAccepted: LegalDocumentAcceptance[], captchaToken: string): Observable<ApiResponse<any>> {
		const headers = new HttpHeaders({ 'X-Recaptcha-Token': captchaToken });
		return this.httpClient.post<ApiResponse<any>>(environment.domainUserAPI + "/api/request/SetDocumentsApproval", legalDocumentAccepted, { headers });
	}
}
